class RingView {
    options = {
        defaultSize    : 150,
        startAngle     : 0,
        endAngle       : 360,
        arcWidth       : 2,
        dottedLineCount: 45,
        foreStartColor : '#EDE0DC',
        foreEndColor   : '#FDCC4C',
        dottedLineWidth: 10,
        targetText     : 'Total duration'
    }


    constructor(canvas, width, height, options = this.options) {
        this._canvas        = canvas
        this.options.width  = width
        this.options.height = height
        this.percent        = 0       // [0.0,1.0]
        this.arcCenterX     = width / 2
        this.radius         = Math.min(width, height) / 2
        this.currentValue   = 0
        this.maxValue       = 100
        this.text           = '0ug'
        this.options        = {...this.options, options}
    }

    setValue(text, currentValue, maxValue) {
        this.text         = text
        this.maxValue     = maxValue
        this.currentValue = currentValue
        this.percent      = currentValue / maxValue
        this.draw()
    }

    draw() {
        this._canvas.clearRect(0, 0, this.options.width, this.options.height)
        this._drawCircle()
        this._drawText()
    }

    _drawCircle() {
        const canvas      = this._canvas
        const degreeUnit  = Math.PI * 2.0 / this.options.dottedLineCount
        const currentLine = this.options.dottedLineCount * this.percent
        for (let i = 0; i < this.options.dottedLineCount; i++) {
            canvas.save()
            const degree = i * degreeUnit
            const startX = this.arcCenterX + Math.sin(degree) * (this.radius - this.options.dottedLineWidth)
            const startY = this.arcCenterX - Math.cos(degree) * (this.radius - this.options.dottedLineWidth)

            const stopX = this.arcCenterX + Math.sin(degree) * this.radius
            const stopY = this.arcCenterX - Math.cos(degree) * this.radius

            canvas.beginPath();
            if (i < currentLine) {
                canvas.strokeStyle = this.options.foreEndColor
            } else {
                canvas.strokeStyle = this.options.foreStartColor
            }

            canvas.lineWidth = 1
            canvas.moveTo(startX, startY)
            canvas.lineTo(stopX, stopY)
            canvas.stroke()
            canvas.restore()
        }
    }

    _drawText() {
        const canvas = this._canvas

        canvas.save()
        canvas.font         = "15px"
        canvas.textAlign    = 'center'
        canvas.fillStyle    = "#000000"
        canvas.textBaseline = "middle";
        canvas.fillText(this.options.targetText, this.options.width / 2, this.options.height / 2 - 13)
        canvas.restore()

        canvas.save()
        canvas.font      = "bold 20px serif"
        canvas.textAlign = 'center'
        canvas.fillStyle = "#000000"
        canvas.fillText(this.text, this.options.width / 2, this.options.width / 2 + 13)
        canvas.restore()
    }
}

export default RingView