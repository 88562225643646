<template>
    <div id="app">
        <keep-alive :include="routerIncludes">
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
    import apiService from './repository/api/ApiService'
    import "./assets/css/common.scss"
    import log from "./repository/log/log"

    export default {
        name      : 'App',
        components: {},
        data() {
            return {
                imageUrl      : '',
                routerIncludes: ['Home', 'Message', 'Mine', 'MineFAQ', "IngredientInfo"]
            }
        },
        mounted() {
            const _this = this;
            apiService.splashPage({
                success(data) {
                    _this.$router.replace({name: 'Splash', params: {imageUrl: data.startupPage}})
                },
                fail() {

                }
            })
        },
        watch     : {
            $route(to, from) {
                log(`to = ${to.name} , from = ${from.name}`)
                if (to.name === 'Pair') {
                    this.routerIncludes = []
                } else if (to.name === 'Home' && from.name === 'Pair') {
                    this.routerIncludes = ['Home', 'Message', 'Mine', 'MineFAQ', "IngredientInfo"]
                }
            }
        }
    }
</script>

<style lang="scss">

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        padding: 0;
        margin: 0;
        background: #FCF5F5;
        width: 100%;
        height: 100%;
    }
</style>
