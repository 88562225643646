import apiService from '../api/ApiService'
import storeRepository, {KEY_USER} from './StoreRepository'
import store from "../../vuex/AppStore"
import StoreType from "../../vuex/StoreType"
import router from "../../router/routers"


const KEY_LANGUAGE   = "language"
const KEY_ASCRIPTION = "ascription"
const KEY_TOKEN      = "token"

class UserRepository {
    constructor() {
        this.language   = localStorage.getItem(KEY_LANGUAGE) || 1
        this.ascription = localStorage.getItem(KEY_ASCRIPTION) || 5
    }

    modifyLanguage(language) {
        this.language = language
        localStorage.setItem(KEY_LANGUAGE, language)
    }

    async login(data) {
        data       = {...data, languages: this.language, ascription: this.ascription}
        const user = await apiService.login(data)
        console.log(user)
        storeRepository.saveUser(user)
        storeRepository.saveToken(user.token)
        this._token = user.token
        this._user  = user
        store.commit(StoreType.COMMIT_TYPE.COMMIT_USER_INFO, user)
    }

    async register(data) {
        data        = {...data, languages: this.language, ascription: this.ascription}
        const token = await apiService.register(data)
        this._token = token
        storeRepository.saveToken(token)
        console.log(`token = ${token}`)
    }

    async sendVerifyEmail(email) {
        const data = {email}
        return await apiService.sendEmail(data)
    }

    async verifyCode(email, code) {
        const data = {email, code}
        return await apiService.verifyCode(data)
    }

    async resetPassword(email, code, password) {
        const data = {email, code, password}
        return await apiService.resetPassword(data)
    }

    async getUserInfo() {
        let userInfo = await apiService.getUserInfo()
        userInfo     = {...store.state.userInfo, ...userInfo}
        store.commit(StoreType.COMMIT_TYPE.COMMIT_USER_INFO, userInfo)
        return userInfo
    }

    async isUnreadMessage() {
        return await apiService.isUnreadMessage()
    }

    async messageList(data) {
        return await apiService.messageList(data)
    }

    async messageDetail(id) {
        return await apiService.messageDetail({id})
    }

    async mineFaq() {
        return await apiService.mineFaq()
    }

    async myDevices() {
        return await apiService.myDevices()
    }

    isLogin() {
        return storeRepository.getToken() != null && storeRepository.getToken() !== ''
    }

    logout() {
        storeRepository.clearAll()
        router.replace({name: 'Login'})
    }
}

const userRepository = new UserRepository()
export default userRepository