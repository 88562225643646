<template>
    <div class="splash">
        <img class="splash" alt="" :src="$route.params.imageUrl"/>
    </div>
</template>

<script>
    import userRepository from "../repository/store/UserRepository"

    export default {
        name : 'Splash',
        props: {
            url: String
        },
        mounted() {
            const _this = this;
            setTimeout(() => {
                if (!userRepository.isLogin()) {
                    _this.$router.replace({name: 'Login'})
                    return
                }
                _this.$router.replace({name: 'Pair'})
                // _this.$router.replace({name: 'Home'})
            }, 3000)
        }
    }
</script>

<style scoped>
    .splash {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
    }

</style>
