<template>
    <div class="mine-faq-container">

        <div class="toolbar pt-16 pb-16">
            <div class="back-container" @click="backClick">
                <span class="back"></span>
            </div>
            <span class="title">FAQ</span>
            <div class="right-menu">
            </div>
        </div>

        <FAQItem class="faq-container" v-for="item in faqItems" :faq-item="item" :key="item.id"/>
    </div>
</template>

<script>
    import FAQItem from "./FAQItem"
    import userRepository from "../repository/api/ApiService"

    export default {
        name      : "MineFAQ",
        components: {FAQItem},
        data() {
            return {
                faqItems: []
            }
        },
        methods   : {
            backClick() {
                this.$router.back()
            },
        },
        mounted() {
            userRepository.mineFaq()
                .then(data => {
                    this.faqItems = data
                })
        }
    }
</script>

<style lang="scss" scoped>
    .mine-faq-container {
        display: flex;
        flex-direction: column;
        .faq-container {
            margin-top: 56px;
        }
    }
</style>