<template>
    <div class="message-container">

        <div class="mt-56">
            <MessageItem v-for="item in messageList" :key="item.id" :message="item"/>
        </div>

        <div class="toolbar pt-16 pb-16">
            <div class="back-container" @click="backClick">
                <span class="back"></span>
            </div>
            <span class="title">Message List</span>
            <div class="right-menu">
            </div>
        </div>
    </div>
</template>

<script>
    import MessageItem from "./MessageItem"
    import userRepository from '../repository/store/UserRepository'

    export default {
        name      : "Message",
        components: {MessageItem},
        data() {
            return {
                messageList: []
            }
        },
        methods   : {
            backClick() {
                this.$router.back()
            },
        },
        activated() {
            const data = {
                pageNum : 1,
                pageSize: 100
            }
            userRepository.messageList(data)
                .then(data => {
                    this.messageList = data
                })
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    .message-container {

    }
</style>