import {Toast} from "vant"

export const toast        = (msg) => Toast(msg)
export const loading      = (msg) => Toast.loading({
    message    : msg,
    forbidClick: true,
})
export const clearToast   = () => Toast.clear()
export const successToast = (msg) => Toast.success(msg)
export const failToast    = (msg) => Toast.fail(msg)

export const initCanvasRatio = (canvasEl) => {
    const canvas       = canvasEl.getContext('2d')
    const backingStore = canvas.backingStorePixelRatio ||
        canvas.webkitBackingStorePixelRatio ||
        canvas.mozBackingStorePixelRatio ||
        canvas.msBackingStorePixelRatio ||
        canvas.oBackingStorePixelRatio ||
        canvas.backingStorePixelRatio || 1
    const ratio        = (window.devicePixelRatio || 1) / backingStore

    const oldWidth = canvasEl.width
    const oldHeight = canvasEl.height

    canvasEl.width = oldWidth * ratio
    canvasEl.height = oldHeight * ratio
    canvasEl.style.width = `${oldWidth}px`
    canvasEl.style.height =  `${oldHeight}px`
    canvas.scale(ratio, ratio)

    return {
        canvas,width:oldWidth,height:oldHeight
    }
}