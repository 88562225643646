<template>
    <div>
        <canvas ref="canvas" width="166px" height="166px"></canvas>
    </div>
</template>

<script>
    import log from "../repository/log/log"
    import {PREHEAT_STATUS} from "../repository/bluetooth/BluetoothRepository"

    export default {
        name: "WaveProgress",

        props: {
            preheatStatus: {
                type   : Number,
                default: PREHEAT_STATUS.END_PREHEAT
            },
            preheatSecond: {
                type   : Number,
                default: 12
            }
        },

        data() {
            return {
                flat       : 300,
                speed      : 7,
                rate       : 0.0,
                distance   : 180,
                wave       : 30,
                anim       : null,
                preheatIcon: require("../assets/preheat/preheating.png"),
            }
        },

        methods : {
            drawCircle(ctx, mW, color) {
                ctx.beginPath()
                ctx.strokeStyle = color
                ctx.arc(mW / 2, mW / 2, mW / 2 - 2, 0, 2 * Math.PI)
                ctx.stroke()
                ctx.beginPath()
                ctx.arc(mW / 2, mW / 2, mW / 2 - 3, 0, 2 * Math.PI)
                ctx.clip()
            },
            drawSin(ctx, mW, color1, color2, wav, dY) {
                ctx.save()
                ctx.beginPath()
                ctx.moveTo(0, mW)
                ctx.lineTo(0, dY)
                ctx.quadraticCurveTo(mW / 4, dY - wav, mW / 2, dY)
                ctx.lineTo(mW / 2, dY)
                ctx.quadraticCurveTo((mW * 3) / 4, dY + wav, mW, dY)
                ctx.lineTo(mW, mW)
                ctx.lineTo(0, mW)
                ctx.fillStyle = color1
                ctx.fill()
                ctx.restore()
            },
            init() {
                const canvas1El = this.$refs.canvas

                const {width, height} = canvas1El
                if (width === 0 || height === 0) {
                    return
                }
                const ctx1 = canvas1El.getContext('2d')
                ctx1.imageSmoothingEnabled = true
                this.ctx1  = ctx1
                this.mW    = width
                const mW   = width

                this.canvas2        = document.createElement('canvas')
                this.canvas2.width  = mW
                this.canvas2.height = mW
                this.ctx2           = this.canvas2.getContext('2d')
                this.ctx2.imageSmoothingEnabled = true

                this.canvas3        = document.createElement('canvas')
                this.canvas3.width  = mW
                this.canvas3.height = mW
                this.ctx3           = this.canvas3.getContext('2d')
                this.ctx3.imageSmoothingEnabled = true

                const rate = this.rate, wave = this.wave

                if (this.preheatStatus === PREHEAT_STATUS.START_PREHEAT) {
                    this.drawCircle(ctx1, mW, '#ED6C0F7F')
                    this.drawSin(this.ctx2, mW, '#ED6C0F', '#ED6C0F', wave, mW - mW * rate)
                    this.drawSin(this.ctx3, mW, '#ED6C0F7F', '#ED6C0F7F', wave, mW - mW * rate)
                    this.drawPreheatIcon()
                } else {
                    this.stopPreheatDraw()
                }


                // var rate1 = rate, wave1 = wave
                // const _this = this
                //
                // function animation() {
                //     if (rate !== rate1 || wave1 !== wave) {
                //         ctx2.clearRect(0, 0, mW, mW)
                //         ctx3.clearRect(0, 0, mW, mW)
                //         rate1 = rate
                //         wave1 = wave
                //         drawSin(ctx2, mW, '#1c86d1', '#1c86d1', wave, mW - mW * rate)
                //         drawSin(ctx3, mW, 'rgba(28, 134, 209, 0.5)', 'rgba(28, 134, 209, 0.5)', wave, mW - mW * rate)
                //     }
                //
                //     ctx1.clearRect(0, 0, mW, mW)
                //     ctx1.drawImage(_this.canvas2, x, 0, mW + flat, mW)
                //     ctx1.drawImage(_this.canvas2, x - mW - flat, 0, mW + flat, mW)
                //     ctx1.drawImage(_this.canvas3, x + distance, 0, mW + flat, mW)
                //     ctx1.drawImage(_this.canvas3, x - mW + distance - flat, 0, mW + flat, mW)
                //     x >= (mW - speed + flat) ? x = 0 : x += speed
                //     requestAnimationFrame(animation)
                // }
                // animation()
            },
            drawPreheatIcon() {
                const ctx1 = this.ctx1
                const mW   = this.mW
                const img  = new Image()
                img.width  = 20
                img.height = 28
                img.src    = this.preheatIcon
                ctx1.drawImage(img, (mW - img.width) / 2, 6, 20, 28)

                ctx1.textAlign    = "center"
                ctx1.font         = "12px serif"
                ctx1.fillStyle    = "#F6F6F6"
                ctx1.textBaseline = "middle"
                ctx1.fillText(`Preheating`, mW / 2, 20 + 28)
            },
            stopPreheatDraw() {
                const ctx1 = this.ctx1
                const mW   = this.mW

                ctx1.clearRect(0, 0, this.mW, this.mW)
                ctx1.beginPath()
                ctx1.fillStyle = "#999999"
                ctx1.arc(mW / 2, mW / 2, mW / 2, 0, 2 * Math.PI)
                ctx1.fill()

                //画draw
                ctx1.textAlign    = "center"
                ctx1.font         = "16px serif"
                ctx1.fillStyle    = "#F6F6F6"
                ctx1.textBaseline = "middle"
                ctx1.fillText(`Wating for preheat`, mW / 2, mW / 2)
            },
            start() {
                let rate    = this.rate, wave = this.wave
                const _this = this
                let second  = this.preheatSecond
                let x       = 0
                this.init()

                function animation() {
                    const mW       = _this.mW
                    const flat     = _this.flat
                    const distance = _this.distance
                    const speed    = _this.speed
                    const ctx1     = _this.ctx1
                    const ctx2     = _this.ctx2
                    const ctx3     = _this.ctx3


                    if (_this.rate !== rate || wave !== _this.wave) {
                        ctx2.clearRect(0, 0, mW, mW)
                        ctx3.clearRect(0, 0, mW, mW)
                        rate = _this.rate
                        wave = _this.wave
                        _this.drawSin(_this.ctx2, mW, '#ED6C0F', '#ED6C0F', wave, mW - mW * rate)
                        _this.drawSin(_this.ctx3, mW, '#ED6C0F7F', '#ED6C0F7F', wave, mW - mW * rate)
                    }

                    ctx1.clearRect(0, 0, mW, mW)
                    ctx1.drawImage(_this.canvas2, x, 0, mW + flat, mW)
                    ctx1.drawImage(_this.canvas2, x - mW - flat, 0, mW + flat, mW)
                    ctx1.drawImage(_this.canvas3, x + distance, 0, mW + flat, mW)
                    ctx1.drawImage(_this.canvas3, x - mW + distance - flat, 0, mW + flat, mW)

                    _this.drawPreheatIcon()


                    //画draw
                    if (second >= 0) {
                        ctx1.save()
                        ctx1.textAlign    = "center"
                        ctx1.font         = "bold 20px serif"
                        ctx1.fillStyle    = "#F6F6F6"
                        ctx1.textBaseline = "middle"
                        ctx1.fillText(`${second.toFixed(0)}s`, mW / 2, mW / 2)
                        ctx1.restore()
                    }

                    x >= (mW - speed + flat) ? x = 0 : x += speed
                    if (_this.rate >= 1) {
                        _this.stop()
                        return
                    }
                    _this.rate += _this.percent
                    second -= (1000 / 60 / 1000)
                    _this.anim = requestAnimationFrame(animation)
                }

                animation()
            },
            stop() {
                this.rate = 0.0
                if (this.anim) {
                    cancelAnimationFrame(this.anim)
                    this.anim = null
                }
                this.init()
            }

        },
        watch   : {
            preheatStatusChanged(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue === PREHEAT_STATUS.START_PREHEAT) {
                        this.rate = 0.0
                        this.start()
                    } else {
                        this.stop()
                    }
                }
            },
            percent(newValue, oldValue) {

            }
        },
        computed: {
            percent() {
                //一秒刷60次，
                return 100 / (this.preheatSecond * 60) / 100
            },
            preheatStatusChanged() {
                return this.preheatStatus
            }
        },
        mounted() {
            this.init()
            // this.start()
        }
    }
</script>

<style lang="scss" scoped>
    .wave-progress {
        width: 100%;
        height: 166px;
        position: relative;
        text-align: center;

        .frame-layout {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 166px;
        }

        .statistics {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 166px;


            .second-container {
                height: 166px;
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
                justify-content: center;
                color: #000000;
                font-size: 20px;
            }
        }

    }
</style>