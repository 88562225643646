import apiService from "../api/ApiService"
import store from "../../vuex/AppStore"
import StoreType from "../../vuex/StoreType"

class DeviceRepository {

    async syncSmokeData(data) {
        const smokeData = {
            year              : data.year,
            month             : data.month,
            day               : data.day,
            deviceNumber      : data.deviceNumber,
            podId             : data.podId,
            smokenumGroup     : `\{\"0-4\":${data.t1},\"4-8\":${data.t2},\"8-12\":${data.t3},\"12-16\":${data.t4},\"16-20\":${data.t5},\"20-24\":${data.t6}\}`,
            todaySmokeDuration: data.todaySmokeDuration,
            totalSmokeTime    : data.totalSmokeTime
        }
        return await apiService.syncSmokeData(smokeData)
    }

    async deviceConnection(batch, macAddress, podId) {
        return await apiService.deviceConnection({
            productionBatch  : batch,
            deviceNumber     : macAddress,
            smokeRecordNumber: podId
        })
    }

    async cartridgeInfo(podId) {
        const cartridgeInfoServer = await apiService.cartridgeInfo({
            smokeRecordNumber: podId
        })
        store.commit(StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INFO_SERVER, cartridgeInfoServer)
        return cartridgeInfoServer
    }

    async diyTemperatureMode(podId) {
        const diyTemperatureList = await apiService.operatingModeList({
            smokeRecordNumber: podId
        })
        let data                 = []
        diyTemperatureList.forEach((item, i) => {
            const name            = item.operatingModeName
            const temperatureData = []
            item.temperature.split(",").forEach((item, index) => {
                temperatureData.push({
                    x: (index + 1),
                    y: parseFloat(item)
                })
            })
            data.push({name, secondTemperature: temperatureData})
        })

        store.commit(StoreType.COMMIT_TYPE.COMMIT_DIY_TEMPERATURE_MODE, data)
        return data
    }

    async bindDevice(macAddress) {
        return await apiService.bindDevice(macAddress)
    }

    async unbindDevice(deviceId) {
        return await apiService.unbindDevice(deviceId)
    }

    async modifyDeviceName(deviceId, deviceName) {
        return await apiService.modifyDeviceName(deviceId, deviceName)
    }

    async getDeviceNameFromServer(macAddress, deviceName) {
        return await apiService.getDeviceNameFromServer(macAddress, deviceName)
    }

    async synchronizeTime(macAddress, deviceType, timeZone) {
        return await apiService.synchronizeTime(macAddress, deviceType, timeZone)
    }

    async supplementRecord(smokeRecordNumber, pageNum = 1) {
        return await apiService.supplementRecord(smokeRecordNumber, pageNum)
    }
}

const deviceRepository = new DeviceRepository()
export default deviceRepository