<template>
    <div class="profile-container">

        <div class="avatar-container mt-56">
            <span class="avatar-name ml-16">Avatar</span>
            <span class="placeholder"></span>
            <!--<template v-if="!isEdit">-->
                <van-image
                        width="46px"
                        height="46px"
                        fit="cover"
                        class="mr-16"
                        :src="userInfo.picture"
                        round/>
            <!--</template>-->
            <!--<template v-else>-->
                <!--<van-uploader :after-read="afterRead">-->
                    <!--<van-image-->
                            <!--width="46px"-->
                            <!--height="46px"-->
                            <!--fit="cover"-->
                            <!--class="mr-16"-->
                            <!--:src="imagePath"-->
                            <!--round/>-->
                <!--</van-uploader>-->
            <!--</template>-->

        </div>

        <div class="info-container">


            <template v-if="!isEdit">
                <div class="mine-menu" v-for="(item,i) in profileInfoMap" @click="itemClick(i)">
                    <span class="item ml-16">{{item.left}}</span> <span class="mr-16">{{item.right}}</span>
                </div>
            </template>
            <template v-else>
                <div class="mine-menu" v-for="(item,i) in formData">
                    <span class="item ml-16">{{item.left}}</span>
                    <template v-if="i === 1">
                        <van-radio-group v-model="item.model" direction="horizontal" checked-color="#FDBA04">
                            <van-radio name="1">Male</van-radio>
                            <van-radio name="0">Female</van-radio>
                        </van-radio-group>
                    </template>
                    <template v-else>
                        <div class="mr-16">
                            <van-field v-model="item.right" class="filed-background"
                                       :placeholder="item.placeholder"
                                       input-align="right"/>
                        </div>
                    </template>
                </div>
            </template>

        </div>

        <div class="sign-out" @click="onSignOut">
            <div class="sign-out-btn">Sign out</div>
        </div>

        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
                <span class="back" @click="backClick"></span>
            </div>
            <span class="title">MyInfo</span>
            <span class="right" @click="onEditClick">{{isEdit?`Save`:`Edit`}}</span>
        </div>
    </div>
</template>

<script>
    import log from "../repository/log/log"
    import apiService from "../repository/api/ApiService"
    import storeRepository from "../repository/store/StoreRepository"
    import userRepository from "../repository/store/UserRepository"

    export default {
        name    : "Profile",
        data() {
            return {
                isEdit   : false,
                imagePath: this.$store.state.userInfo.picture,
                formData : [
                    {
                        left       : "Nickname",
                        right      : `${this.$store.state.userInfo.nickName}`,
                        placeholder: '3–50 character',
                    },
                    {
                        left : "Gender",
                        right: this.$store.state.userInfo.gender === 0 ? 'Famale' : 'Male',
                        model: `${this.$store.state.userInfo.gender}`
                    },
                    {
                        left       : "Age", right: this.$store.state.userInfo.age || '',
                        placeholder: 'age in 18 ~ 100',
                    },
                    {
                        left       : "ZIP code", right: this.$store.state.userInfo.zipCode || '',
                        placeholder: 'input zipcode',
                    }
                ]
            }
        },
        methods : {
            backClick() {
                if (this.isEdit) {
                    this.onEditClick()
                    return
                }
                this.$router.back()
            },

            onEditClick() {
                if (this.isEdit) {
                    const commitData = {
                        nickName: this.formData[0].right,
                        gender  : this.formData[1].model,
                        picture : this.imagePath,
                        age     : this.formData[2].right,
                        zipCode : this.formData[3].right
                    }
                    apiService.modifyProfile(commitData).then((data) => {
                        let userInfo = this.$store.state.userInfo
                        userInfo     = {...userInfo, ...commitData}
                        storeRepository.saveUser(userInfo)
                        this.isEdit = !this.isEdit
                    })
                    return
                }
                this.isEdit = !this.isEdit
            },

            onSignOut() {
                userRepository.logout()
            },
            afterRead(file) {
                const formData = new FormData()
                formData.append("file", file.file, file.file.name)
                apiService.uploadFile(formData)
                    .then((data) => {
                        log(`Image path = ${data}`)
                        this.imagePath = data
                    })
            },
            modifyProfile() {
                apiService.modifyProfile({})
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            },
            profileInfoMap() {
                const userInfo = this.userInfo
                return [
                    {
                        left       : "Nickname",
                        right      : userInfo.nickName,
                        placeholder: '3–50 character',
                    },
                    {
                        left : "Gender",
                        right: (userInfo.gender || 'Male') === 0 ? 'Famale' : 'Male',
                        model: `${userInfo.gender}`
                    },
                    {
                        left       : "Age", right: userInfo.age || '',
                        placeholder: 'age in 18 ~ 100',
                    },
                    {
                        left       : "ZIP code", right: userInfo.zipCode || '',
                        placeholder: 'input zipcode',
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

    .profile-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        color: #000000;

        .avatar-container {
            width: 100%;
            background: #FCF5F5;
            display: flex;
            padding-top: 21px;
            padding-bottom: 21px;
            flex-direction: row;
            align-items: center;

            .avatar-name {
                text-align: center;
            }

            .placeholder {
                flex: 1;
            }
        }

        .info-container {
            width: 100%;
            color: #000000;
            background: #FCF5F5;
            font-size: 14px;

            .mine-menu {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 0.5px solid #666666;
                box-sizing: border-box;
                padding: 16px 0;

                .item {
                    flex: 1;
                }
            }
        }

        .right {
            color: #ED6C0F;
            padding: 0 16px;
        }

        .sign-out {
            color: #000;
            width: 80%;
            margin-top: 100px;

            .sign-out-btn {
                padding: 12px 0;
                text-align: center;
                background: #FDBA04;
                border-radius: 16px;
            }
        }
    }

    .gender-selector {

    }

    .van-field__body {
        line-height: 0;
    }

    .van-cell {
        padding: 0;
        font-size: 14px;
    }


</style>