const CONNECT_STATE_DISCONNECTED = 1
const CONNECT_STATE_CONNECTING   = 2
const CONNECT_STATE_CONNECTED    = 3

const COMMIT_DEVICE_INFO              = "deviceInfo"
const COMMIT_CONNECT_STATE            = "connectStateChanged"
const COMMIT_POD_ID                   = "podId"
const COMMIT_SYNC_SMOKE_DATA          = "syncData"
const COMMIT_IS_FIRE                  = "isFire"
const COMMIT_CARTRIDGE_INFO_SERVER    = "cartridgeInfoServer"
const COMMIT_SECOND_IN_TAKE           = "secondInTake"
const COMMIT_CARTRIDGE_INSERT_CHANGED = "cartridgeInsertChanged"
const COMMIT_BATTERY_CHANGED          = "batteryChanged"
const COMMIT_PREHEAT_INFO             = "preheatInfo"
const COMMIT_TEMPERATE_MODE           = "temperateMode"
const COMMIT_PREHEAT_STATUS           = "preheatStatus"
const COMMIT_DIY_TEMPERATURE_MODE     = "diyTemperatureMode"
const COMMIT_USER_INFO                = "userInfo"
const COMMIT_SWITCH_TEMPERATURE_MODE  = "switchTemperatureMode"

const TASTE_TYPE_PREHEAT           = 1
const TASTE_TYPE_DOSAGE            = 2
const TASTE_TYPE_TEMPERATURE       = 3
const PREHEAT_STATUS_ENTER_PREHEAT = 1
const PREHEAT_STATUS_EXIT_PREHEAT  = 2
const PREHEAT_STATUS_START_PREHEAT = 3
const PREHEAT_STATUS_END_PREHEAT   = 4

export const RECEIVED_ENTER_DIY_TEMPERATURE_CURVE = 1
export const RECEIVED_EXIT_DIY_TEMPERATURE_CURVE  = 2

export default {
    connectState: {
        CONNECT_STATE_DISCONNECTED,
        CONNECT_STATE_CONNECTING,
        CONNECT_STATE_CONNECTED,
    },
    COMMIT_TYPE : {
        COMMIT_DEVICE_INFO,
        COMMIT_CONNECT_STATE,
        COMMIT_POD_ID,
        COMMIT_SYNC_SMOKE_DATA,
        COMMIT_IS_FIRE,
        COMMIT_CARTRIDGE_INFO_SERVER,
        COMMIT_SECOND_IN_TAKE,
        COMMIT_CARTRIDGE_INSERT_CHANGED,
        COMMIT_BATTERY_CHANGED,
        COMMIT_PREHEAT_INFO,
        COMMIT_TEMPERATE_MODE,
        COMMIT_PREHEAT_STATUS,
        COMMIT_DIY_TEMPERATURE_MODE,
        COMMIT_USER_INFO,
        COMMIT_SWITCH_TEMPERATURE_MODE
    },
    PREHEAT_TYPE: {
        PREHEAT_STATUS_ENTER_PREHEAT,
        PREHEAT_STATUS_EXIT_PREHEAT,
        PREHEAT_STATUS_START_PREHEAT,
        PREHEAT_STATUS_END_PREHEAT,
    },
    TASTE_TYPE  : {
        TASTE_TYPE_PREHEAT,
        TASTE_TYPE_DOSAGE,
        TASTE_TYPE_TEMPERATURE
    }
}

