import {toast} from '../util/util'

const EMAIL_REGEX    = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
const PASSWORD_REGEX = /[a-zA-Z0-9]{6,20}/
const DIGIT_REGEX    = /\d{2,5}/
import log from '../repository/log/log'

const rule = {
    email          : {
        validator(val) {
            if (val.trim() === '') {
                toast(rule.email.errorMessage.emptyTips)
                return false
            }

            if (val.trim().length > 32) {
                toast(rule.email.errorMessage.lengthTips)
                return false
            }

            if (!EMAIL_REGEX.test(val)) {
                toast(rule.email.errorMessage.invalidTips)
                return false
            }
            return true
        },
        errorMessage: {
            emptyTips  : 'E-mail can not empty',
            lengthTips : 'E-mail length is more than 32',
            invalidTips: 'Invalid E-mail or E-mail is empty',
        }
        // required: true
    },
    password       : {
        validator(val) {
            if (val.trim() === '') {
                toast(rule.password.errorMessage.emptyTips)
                return false
            }

            if (val.trim().length > 20) {
                toast(rule.password.errorMessage.lengthTips)
                return false
            }

            if (!PASSWORD_REGEX.test(val)) {
                toast(rule.password.errorMessage.invalidTips)
                return false
            }
            return true
        },
        errorMessage: {
            emptyTips  : 'Password can not empty',
            lengthTips : 'Password length is more than 20',
            invalidTips: 'Password only in [a-z A-Z 0-9]',
        }
    },
    confirmPassword: {
        validator(val, origin) {
            if (val.trim() === '') {
                toast(rule.password.errorMessage.emptyTips)
                return false
            }

            if (val.trim().length > 20) {
                toast(rule.password.errorMessage.lengthTips)
                return false
            }

            if (!PASSWORD_REGEX.test(val)) {
                toast(rule.password.errorMessage.invalidTips)
                return false
            }
            if (val !== origin) {
                toast(rule.password.errorMessage.confirmTips)
                return false
            }
            return true
        },
        errorMessage: {
            emptyTips  : 'Password can not empty',
            lengthTips : 'Password length is more than 20',
            invalidTips: 'Password only in [a-z A-Z 0-9]',
            confirmTips: 'ConfirmPassword is not equal password',
        }
    }
}

export default rule