<template>
    <div class="faq-detail-container">

        <div class="detail mt-56 ml-16 mr-16">
            <p class="title">Q:{{$route.params.faqItem.title}}</p>
            <p class="content">A:{{$route.params.faqItem.content}}</p>
        </div>


        <div class="toolbar pt-16 pb-16">
            <div class="back-container" @click="backClick">
                <span class="back"></span>
            </div>
            <span class="title">FAQ</span>
            <div class="right-menu">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : "FAQDetail",
        props: {},
        methods: {
            backClick() {
                this.$router.back()
            },
        }
    }
</script>

<style lang="scss" scoped>
    .faq-detail-container {
        display: flex;
        color: #000000;
        font: 15px bold;
        flex-direction: column;

        .detail {
            .title {
                color: #000000;
            }

            .content {
                color: #000000;
            }
        }
    }
</style>