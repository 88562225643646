import log from "../repository/log/log"

class TemperatureGraphView {
    options = {
        showLine          : false,
        smooth            : 0.5,
        xAxisTextSize     : 14,
        xAxisTextColor    : '#666666',
        xAxisDotLineWidth : 0.5,
        xAxisDotLineColor : '#DDDDDD',
        defaultPillarColor: '#666666',
        shaderPillarColor : '#ED6C0F',
        pillarWidth       : 2,
        circleWidth       : 20,
        circleMargin      : 7,
        circleColor       : '#FF8314',
        curveColor        : '#FF8314',
        curveBgColor      : '#222222',
        data              : [],
    }

    _originPointData      = []
    _valuePointList       = []
    _controlPointList     = []
    _temperaturePointList = []

    _scaleX = 0
    _scaleY = 0

    _secondMetrics      = 0
    _temperatureMetrics = 0

    _currentXValue = 0

    _anim = null

    constructor(canvas, width, height) {
        this._canvas        = canvas
        this.options.width  = width
        this.options.height = height

    }

    init(options) {
        this.options          = {...this.options, ...options}
        this._originPointData = []
        if (!options.data || options.data.length === 0) {
            return
        }
        options.data.forEach((item, i) => {
            if (i < 6) {
                this._originPointData.push({x: (i + 1), y: item.y})
            }
        })
        //计算秒数的宽度
        this._canvas.font                  = "13px"
        this._canvas.imageSmoothingEnabled = true
        this._secondMetrics                = this._canvas.measureText("1s")
        this._temperatureMetrics           = this._canvas.measureText("  900℉  ")

        //设置scaleX， scaleY
        if (this.options.showLine) {
            this._scaleX = (this.options.width - this._temperatureMetrics.width - this._secondMetrics.width / 2) / (this._originPointData.length - 1)
            this._scaleY = (this.options.height - 2 * this._secondMetrics.width) / 10.0
        } else {
            this._scaleX = (this.options.width) / (this._originPointData.length - 1)
            this._scaleY = (this.options.height) / 10.0
        }

        this._handleData()
    }

    _calculateValuePoint(originData, max, scaleX, scaleY) {
        this._valuePointList = []
        originData.forEach((item, i) => {
            let offset = 0
            if (this.options.showLine) {
                offset = this._temperatureMetrics.width
            }
            const x = i * scaleX + offset
            const y = ((max - item.y) / 100) * scaleY
            this._valuePointList.push({x, y})
        })
    }

    _calculateControlPoint(pointList) {
        this._controlPointList = []
        if (pointList.length <= 1) {
            return
        }

        const size = pointList.length
        pointList.forEach((point, i) => {
            if (i === 0) {//第一项
                //添加后控制点
                const nextPoint = pointList[i + 1]
                const controlX  = point.x + (nextPoint.x - point.x) * this.options.smooth
                const controlY  = point.y
                this._controlPointList.push({x: controlX, y: controlY})
            } else if (size - 1 === i) {
                //最后一项
                //添加前控制点
                const lastPoint = pointList[i - 1]
                const controlX  = point.x - (point.x - lastPoint.x) * this.options.smooth
                const controlY  = point.y
                this._controlPointList.push({x: controlX, y: controlY})
            } else {
                //中间项
                const lastPoint    = pointList[i - 1]
                const nextPoint    = pointList[i + 1]
                const k            = (nextPoint.y - lastPoint.y) / (nextPoint.x - lastPoint.x)
                const b            = point.y - k * point.x
                //添加前控制点
                const lastControlX = point.x - (point.x - lastPoint.x) * this.options.smooth
                const lastControlY = k * lastControlX + b
                this._controlPointList.push({x: lastControlX, y: lastControlY})
                //添加后控制点
                const nextControlX = point.x + (nextPoint.x - point.x) * this.options.smooth
                let nextControlY   = (k * nextControlX + b)
                if (nextPoint.y === point.y) nextControlY = point.y
                this._controlPointList.push({x: nextControlX, y: nextControlY})
            }
        })
    }

    _calculateTemperaturePoint() {
        this._temperaturePointList = []
        for (let i = 1; i < 4; i++) {
            this._temperaturePointList.push({x: 0, y: (10 - i * 3) * this._scaleY})
        }
    }

    _handleData() {
        this._calculateValuePoint(this._originPointData, 1000.0, this._scaleX, this._scaleY)
        this._calculateControlPoint(this._valuePointList)
        this._calculateTemperaturePoint()

        if (this.options.showLine) {
            this._currentXValue = this.options.width - this._temperatureMetrics.width
        } else {
            this._currentXValue = 0
        }
    }

    draw() {

        const canvas = this._canvas
        const width  = this.options.width

        const firstPoint = this._valuePointList[0]
        let height       = this.options.height

        canvas.clearRect(0, 0, width, height)

        if (this.options.showLine) {
            canvas.save()
            canvas.font = "13px"
            height      = this.options.height - this._getTextHeight(this._secondMetrics) * 2
            canvas.restore()
        }

        canvas.strokeStyle = "yellow"
        canvas.lineWidth   = 1

        canvas.save()
        canvas.beginPath()
        canvas.moveTo(firstPoint.x, height)                   //移动到初始位置
        canvas.lineTo(firstPoint.x, firstPoint.y)             //移动到起始数据点
        for (let i = 0; i < (this._valuePointList.length - 1) * 2; i += 2) {
            const leftControlPoint  = this._controlPointList[i]
            const rightControlPoint = this._controlPointList[i + 1]
            const rightPoint        = this._valuePointList[i / 2 + 1]
            canvas.bezierCurveTo(leftControlPoint.x, leftControlPoint.y, rightControlPoint.x, rightControlPoint.y, rightPoint.x, rightPoint.y)
        }
        const lastPoint = this._valuePointList[this._valuePointList.length - 1]
        canvas.lineTo(lastPoint.x, height)
        canvas.closePath()
        canvas.clip()

        const defaultLinearGradient = canvas.createLinearGradient(0, 0, width, this.options.height)
        defaultLinearGradient.addColorStop(0, '#666666ED')
        defaultLinearGradient.addColorStop(1, '#33333333')
        canvas.fillStyle = defaultLinearGradient
        canvas.lineWidth = 10
        canvas.fillRect(0, 0, width, this.options.height)


        if (!this.options.showLine) {
            canvas.save()
            canvas.fillStyle = canvas.createLinearGradient(
                0,
                height,
                width,
                height
            )
            canvas.fillRect(0, 0, width, height)
            canvas.restore()
        }

        canvas.save()
        let swipeWidth = this._currentXValue
        if (this.options.showLine) {
            swipeWidth = width
        }
        const swipeLinearGradient = canvas.createLinearGradient(
            0,
            height,
            swipeWidth,
            height
        )
        swipeLinearGradient.addColorStop(0, '#FFFFFF00')
        swipeLinearGradient.addColorStop(1, '#FF8314')
        canvas.fillStyle = swipeLinearGradient
        canvas.fillRect(0, 0, swipeWidth, height)
        canvas.restore()
        canvas.restore()

        //画文字 秒数
        if (this.options.showLine) {
            this._valuePointList.forEach((point, i) => {
                //画树状图 默认颜色
                canvas.save()
                canvas.strokeStyle = this.options.defaultPillarColor
                canvas.lineWidth   = this.options.pillarWidth
                canvas.beginPath()
                canvas.moveTo(point.x, 0)
                canvas.lineTo(point.x, height)
                canvas.stroke()
                canvas.restore()

                //画树状图 默认颜色
                canvas.save()
                const pillarLinearGradient = canvas.createLinearGradient(
                    point.x,
                    point.y,
                    point.x,
                    height
                )
                pillarLinearGradient.addColorStop(0, '#FC6C04')
                pillarLinearGradient.addColorStop(1, '#FFF2E6')
                canvas.strokeStyle = pillarLinearGradient
                canvas.lineWidth   = 2
                canvas.beginPath()
                canvas.moveTo(point.x, point.y)
                canvas.lineTo(point.x, height)
                canvas.stroke()
                canvas.restore()

                //画秒数
                canvas.save()
                canvas.font      = "13px"
                canvas.textAlign = 'center'
                canvas.fillStyle = "#000000"
                canvas.fillText(`${(i + 1)}s`, point.x, height + this._getTextHeight(this._secondMetrics) * 2)
                canvas.restore()
            })

            this._temperaturePointList.forEach((point, i) => {
                //画温度文字
                canvas.save()
                canvas.font         = "13px"
                canvas.fillStyle    = "#F6F6F6"
                canvas.textBaseline = 'middle'
                canvas.fillText(`${(i + 1) * 3 * 100}℉`, point.x, point.y)
                canvas.restore()

                // //画温度对应的虚线
                canvas.save()
                canvas.beginPath()
                canvas.setLineDash([3, 3])
                let offset = 0
                if (this.options.showLine) {
                    offset = this._temperatureMetrics.width + this._secondMetrics.width
                }
                canvas.strokeStyle = this.options.xAxisDotLineColor
                canvas.lineWidth   = this.options.xAxisDotLineWidth
                canvas.moveTo(this._temperatureMetrics.width, point.y)
                canvas.lineTo(width - point.x - this._secondMetrics.width / 2, point.y)
                canvas.stroke()
                canvas.restore()
            })

            this._valuePointList.forEach((point, i) => {
                canvas.save()
                canvas.beginPath()
                canvas.fillStyle = this.options.circleColor
                canvas.arc(point.x, point.y, 4, 0, 2 * Math.PI)
                canvas.fill()
                canvas.restore()

                canvas.save()
                canvas.beginPath()
                canvas.font      = "13px"
                canvas.fillStyle = 'white'
                canvas.arc(point.x, point.y, 2, 0, 2 * Math.PI)
                canvas.fill()
                canvas.restore()
            })
        }
    }

    startSwipe() {
        const animRefreshCount      = 6000 / (1000 / 60) //6秒内刷新的次数
        const _this                 = this
        let currentAnimRefreshTimes = 0
        const viewWidth             = this.options.width

        function swipeChanged() {
            const percent        = currentAnimRefreshTimes / animRefreshCount
            _this._currentXValue = viewWidth * percent
            _this.draw()
            if (currentAnimRefreshTimes <= animRefreshCount) {
                ++currentAnimRefreshTimes
                // log(`percent : ${currentAnimRefreshTimes}     ---  ${percent}  ------ ${_this._currentXValue}`)
                _this._anim = requestAnimationFrame(swipeChanged)
            }
        }

        _this._anim = requestAnimationFrame(swipeChanged)
    }

    stopSwipe() {
        this._currentXValue = 0
        cancelAnimationFrame(this._anim)
    }

    _getTextHeight(metrics) {
        return metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
    }
}

export default TemperatureGraphView