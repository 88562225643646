import Vue from 'vue'
import Vuex from 'vuex'
import StoreType from '../vuex/StoreType'
import router from '../router/routers'
import {INGREDIRENT_TYPE_NICOTINE, INGREDIRENT_TYPE_SUPPLEMENT} from "../repository/bluetooth/BluetoothRepository";
import deviceRepository from "../repository/store/DeviceRepository"

Vue.use(Vuex)

const store = new Vuex.Store({
    state    : {
        deviceInfo           : {
            podId        : '',
            version      : '',
            battery      : 0,
            cartridgeInfo: '',
            macAddress   : '',
            syncTime     : ''
        },
        cartridgeInfoServer  : {
            secondInTake  : 0,
            mainProportion: 0,
            dose          : 0
        },
        syncData             : {},
        connectState         : StoreType.connectState.CONNECT_STATE_DISCONNECTED,
        deviceStatus         : {
            isFire   : false,
            isPreheat: false,
            isInsert : true
        },
        preheatInfo          : {
            preheatTemperature: 0,
            preheatSecond     : 0
        },
        temperatureMode      : {
            name             : '',
            secondTemperature: []
        },
        preheatStatus        : 4,
        diyTemperatureMode   : {
            name             : '',
            secondTemperature: []
        },
        userInfo             : {},
        switchTemperatureMode: false
    },
    mutations: {
        [StoreType.COMMIT_TYPE.COMMIT_DEVICE_INFO](state, payload) {
            state.deviceInfo = {...payload}
        },

        [StoreType.COMMIT_TYPE.COMMIT_SYNC_SMOKE_DATA](state, payload) {
            state.syncData = {...payload, ...{deviceNumber: state.deviceInfo.macAddress}}
            deviceRepository.syncSmokeData(state.syncData)
        },

        [StoreType.COMMIT_TYPE.COMMIT_CONNECT_STATE](state, connectState) {
            state.connectState = connectState
            if (connectState === StoreType.connectState.CONNECT_STATE_CONNECTED) {
                router.push({name: "Home",})
            } else {
                router.replace("Pair")
            }
        },
        [StoreType.COMMIT_TYPE.COMMIT_POD_ID](state, podId) {
            state.deviceInfo.podId = podId
        },

        [StoreType.COMMIT_TYPE.COMMIT_IS_FIRE](state, isFire) {
            if (state.deviceStatus.isFire !== isFire) {
                state.deviceStatus.isFire = isFire
            }
        },

        [StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INFO_SERVER](state, cartridgeInfoServer) {
            state.cartridgeInfoServer = {...cartridgeInfoServer}
        },

        [StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INSERT_CHANGED](state, isInsert) {
            state.deviceStatus.isInsert = isInsert
        },

        [StoreType.COMMIT_TYPE.COMMIT_BATTERY_CHANGED](state, battery) {
            state.deviceInfo.battery = battery
        },

        [StoreType.COMMIT_TYPE.COMMIT_PREHEAT_INFO](state, preheatInfo) {
            state.preheatInfo = {...preheatInfo}
        },

        [StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE](state, temperatureMode) {
            state.temperatureMode = {...temperatureMode}
        },

        [StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS](state, preheatStatus) {
            state.preheatStatus = preheatStatus
        },

        [StoreType.COMMIT_TYPE.COMMIT_DIY_TEMPERATURE_MODE](state, diyTemperatureMode) {
            state.diyTemperatureMode = diyTemperatureMode
        },

        [StoreType.COMMIT_TYPE.COMMIT_USER_INFO](state, userInfo) {
            state.userInfo = userInfo
        },

        [StoreType.COMMIT_TYPE.COMMIT_SWITCH_TEMPERATURE_MODE](state, switchTemperatureMode) {
            state.switchTemperatureMode = switchTemperatureMode
        }
    },
    getters  : {
        doses: state => {
            let secondInTake          = 0
            let mainProportion        = 0
            const cartridgeInfoServer = state.cartridgeInfoServer
            if (cartridgeInfoServer) {
                if (state.deviceInfo.cartridgeInfo.ingredirentType === INGREDIRENT_TYPE_SUPPLEMENT || state.deviceInfo.cartridgeInfo.ingredirentType === INGREDIRENT_TYPE_NICOTINE) {
                    secondInTake = cartridgeInfoServer.dose * 1000 / 0.8 / 450 * cartridgeInfoServer.dose
                } else {
                    secondInTake = cartridgeInfoServer.dose * 1000 / 1 / 450 * cartridgeInfoServer.dose
                }

                if (cartridgeInfoServer.formulaList && cartridgeInfoServer.formulaList.length > 0) {
                    mainProportion = cartridgeInfoServer.formulaList[0].proportion
                }
            }

            return {
                secondInTake,
                mainProportion
            }
        }
    }
})

export default store