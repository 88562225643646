<template>
    <div class="my-devices-container">
        <div class="mt-56 pl-14 pr-14">
            <div class="my-device-item-container pt-16 pb-16" v-for="item in myDevices" :key="item.id">
                <div class="my-device-info">
                    <div class="device-name-container">
                        <span class="my-device-name">{{item.deviceName}}</span>
                        <span class="edit-device" @click="modifyDeviceClick(item)"></span>
                    </div>
                    <div class="my-device-brand mt-8">{{item.deviceModel}}</div>
                </div>
                <button class="unbind" @click="unbind(item)">Unbind</button>
            </div>

        </div>


        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
                <span class="back" @click="backClick"></span>
            </div>
            <span class="title">My Devices</span>
            <div class="right-menu"></div>
        </div>


        <van-popup v-model="showModifyDialog" class="modify-device-dialog" round opened="onPopupOpened">
            <ModifyDeviceName v-show="showModifyDialog"
                              :device-model="clickDevice"
                              v-on:onCloseBindDialog="onCloseBindDialog"
                              v-on:onSubmitData="onSubmitData"/>
        </van-popup>
    </div>
</template>

<script>
    import userRepository from "../repository/store/UserRepository"
    import ModifyDeviceName from "./ModifyDeviceName"
    import deviceRepository from "../repository/store/DeviceRepository"
    import bluetoothRepository from "../repository/bluetooth/BluetoothRepository"

    export default {
        name      : "MyDevices",
        components: {ModifyDeviceName},
        data() {
            return {
                myDevices       : [],
                showModifyDialog: false,
                clickDevice     : {}
            }
        },
        methods   : {
            backClick() {
                this.$router.back()
            },
            onCloseBindDialog() {
                this.showModifyDialog = false
            },
            onSubmitData(device, deviceName) {
                deviceRepository.modifyDeviceName(device.id, deviceName)
                    .then(data => {
                        this.showModifyDialog       = false
                        this.clickDevice.deviceName = deviceName
                    })
                    .catch(err => {

                    })
            },
            modifyDeviceClick(device) { //修改设备名称，显示对话框
                this.clickDevice      = device
                this.showModifyDialog = true
            },

            unbind(device) {           //解绑设备
                deviceRepository.unbindDevice(device.id)
                    .then(data => {
                        this.myDevices.splice(this.myDevices.findIndex(item => item.id === device.id), 1)
                        if (device.deviceNumber === bluetoothRepository.device.macAddress) {
                            bluetoothRepository.disconnect()
                        }
                    })
            }
        },
        mounted() {
            userRepository.myDevices()
                .then(data => {
                    this.myDevices = data
                })
        }
    }
</script>

<style lang="scss" scoped>
    .my-devices-container {
        display: flex;
        flex-direction: column;
    }

    .my-device-item-container {
        flex: 1;
        display: flex;
        flex-direction: row;
        color: #000000;
        border-bottom: 1px solid #2d2d2d;

        .my-device-info {
            flex: 1;
            display: flex;
            flex-direction: column;

            .device-name-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .my-device-name {
                    font-size: 16px;
                    color: #000000;
                }

                .edit-device {
                    width: 15px;
                    height: 15px;
                    margin-left: 8px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    @include bg-image("../assets/ic-edit-device-name");
                }

            }

            .my-device-brand {
                font-size: 12px;
            }
        }

        .unbind {
            text-align: center;
            padding: 4px 12px;
            border: 0;
            background-color: #FDBA04;
            border-radius: 12px;
        }
    }

    .modify-device-dialog {
        width: 80%;
    }
</style>