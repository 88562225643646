<template>
    <div class="message-detail-container mt-56 ">
        <div class="title mt-8 ml-16 mr-16">{{messageDetail.title}}</div>
        <div class="time mt-8 ml-16 mr-16">{{messageDetail.createTime}}</div>
        <div class="content mt-16 ml-16 mr-16">{{messageDetail.content}}</div>

        <div class="toolbar pt-16 pb-16">
            <div class="back-container" @click="backClick">
                <span class="back"></span>
            </div>
            <span class="title">Message Detail</span>
            <div class="right-menu">
            </div>
        </div>
    </div>
</template>

<script>
    import userRepository from "../repository/store/UserRepository"

    export default {
        name: "MessageDetail",
        data() {
            return {
                messageDetail: {
                    title     : '',
                    createTime: '',
                    content   : ''
                }
            }
        },

        methods: {
            backClick() {
                this.$router.back()
            }
        },
        mounted() {
            userRepository.messageDetail(this.$route.params.message.messageId)
                .then(data => {
                    this.messageDetail = data
                })
        }
    }
</script>

<style lang="scss" scoped>
    .message-detail-container {
        display: flex;
        flex-direction: column;

        .title {
            color: #FFFFFF;
        }

        .time {
            color: #999999;
        }

        .content {
            color: #F6F6F6;
        }
    }
</style>