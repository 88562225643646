import VueRouter from 'vue-router'
import Home from "../components/Home";
import Splash from "../components/Splash";
import Scan from "../components/Scan";
import Login from "../components/Login"
import Register from "../components/Register"
import IngredientInfo from "../components/IngredientInfo"
import FormulaContainer from "../components/FormulaContainer"
import Mine from "../components/Mine"
import More from "../components/More"
import Profile from "../components/Profile"
import Improve from "../components/Improve"
import ForgetPassword from "../components/ForgetPassword"
import VueWebView from "../components/VueWebView"
import Message from "../components/Message"
import MessageDetail from "../components/MessageDetail"
import MineFAQ from "../components/MineFAQ"
import FAQDetail from "../components/FAQDetail"
import MyDevices from "../components/MyDevices"
import MyRecord from "../components/MyRecord"

const routes = [
    {path: '/splash', component: Splash, name: 'Splash'},
    {path: '/login', component: Login, name: 'Login'},
    {path: '/register', component: Register, name: 'Register'},
    {path: '/pair', component: Scan, name: 'Pair'},
    {
        path     : '/home',
        component: Home,
        name     : 'Home',
    },
    {path: '/ingredientInfo', component: IngredientInfo, name: 'IngredientInfo'},
    {path: '/mine', component: Mine, name: 'Mine'},
    {path: '/more', component: More, name: 'More'},
    {path: '/profile', component: Profile, name: 'Profile'},
    {path: '/forget', component: ForgetPassword, name: 'Forget'},
    {path: '/improve', component: Improve, name: 'Improve'},
    {path: '/web', component: VueWebView, name: 'Web'},
    {path: '/message', component: Message, name: 'Message'},
    {path: '/messageDetail', component: MessageDetail, name: 'MessageDetail'},
    {path: '/mineFaq', component: MineFAQ, name: 'MineFAQ'},
    {path: '/faqDetail', component: FAQDetail, name: 'FAQDetail'},
    {path: '/myDevices', component: MyDevices, name: 'MyDevices'},
    {path: '/myRecord', component: MyRecord, name: 'MyRecord'}
];
const router = new VueRouter({
    routes
});

export default router