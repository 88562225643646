<template>
    <div class="web-view-container">
        <iframe class="iframe-container" :src="$route.params.url" frameborder="0"></iframe>


        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
                <span class="back" @click="backClick"></span>
            </div>
            <span class="title">{{$route.params.title}}</span>
            <span class="right-menu"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VueWebView",
        data() {
            return {}
        },
        methods: {
            backClick() {
                this.$router.back()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .web-view-container {

        .iframe-container {
            width: 100%;
            height: 100%;
            overflow:auto;
            position: fixed;
            bottom: 0;
            top: 56px;
            left: 0;
            right: 0;
        }
    }
</style>