<template>
    <div class="message-item ml-16 mr-16 " @click="onMessageClick">
        <div class="title-item">
            <div class="title">{{message.title}}</div>
            <span class="time">{{message.createTime}}</span>
        </div>
        <div class="content mt-8">{{message.content}}</div>
    </div>
</template>

<script>
    export default {
        name   : "MessageItem",
        props  : {
            message: {
                type   : Object,
                default: () => {
                }
            }
        },
        methods: {
            onMessageClick() {
                this.$router.push({
                    name: "MessageDetail", params: {message: this.message}
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .message-item {
        display: flex;
        font-size: 14px;
        flex-direction: column;
        padding: 16px 0;
        border-bottom: 0.3px solid #dddddd8f;


        .title-item {
            flex: 1;
            color: #000000;
            display: flex;
            flex-direction: row;

            .title {
                flex: 1;
            }

            .time {
                font-size: 13px;
                color: #999999;
            }
        }

        .content {
            font-size: 13px;
            color: #999999;
        }
    }
</style>