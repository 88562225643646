<template>
    <div id="register">
        <div class="img-box">
            <div :class="logoIcon"></div>
        </div>

        <div class="login-input">
            <div class="user-account-input">
                <van-field v-model="registerData.username" placeholder="E-mail"/>
            </div>

            <div class="mt-13 user-account-input">
                <van-field v-model="registerData.password" placeholder="Password"/>
            </div>
            <div class="mt-13 user-account-input">
                <van-field v-model="registerData.confirmPassword" placeholder="Confirm password"/>
            </div>

            <div class="mt-20 ml-48">
                <van-checkbox v-model="registerData.privacyAgreeChecked" checked-color="#FDBA04">
                    <div class="user-account-checkbox">
                        I agree
                        <span class="agreement">UNI-CORE User Agreement</span> and
                        <span class="agreement">UNI-CORE Privacy Agreement</span>
                    </div>
                </van-checkbox>
            </div>

            <div class="mt-40 ml-40 mr-40">
                <van-button class="mt-16 center-btn user-account-btn" block round type="primary" @click="register">
                    Register
                </van-button>
            </div>

            <div class="user-account-text mt-16 ml-40 mr-40">
                Already have an account?
                <span class="login" @click="routeLogin">log in</span>
            </div>
        </div>
    </div>
</template>

<script>
    import log from "../repository/log/log";

    import userRepository from "../repository/store/UserRepository";
    import rule from "../rule/rule"

    export default {
        name   : "Register",
        data() {
            return {
                registerData: {
                    username           : "",
                    password           : "",
                    confirmPassword    : "",
                    privacyAgreeChecked: true,
                },
            };
        },
        methods: {
            routeLogin() {
                this.$router.replace({name: "Login"});
            },
            register() {
                const {username, password, confirmPassword} = this.registerData;

                if (!rule.email.validator(username)) {
                    return;
                }

                if (!rule.password.validator(password)) {
                    return;
                }

                if (!rule.confirmPassword.validator(confirmPassword, password)) {
                    return;
                }


                userRepository
                    .register({email: username, password})
                    .then((data) => {
                        this.$router.replace({name: "Improve"});
                    })
                    .catch((err) => {
                    });
            },
        },
        computed: {
            logoIcon() {
                if (location.hostname === 'dime.unicoreus.com') {
                    return 'logo-dime'
                } else if (location.hostname === 'app.unicoreus.com') {
                    return 'logo-unicore'
                }else {
                    return 'logo-unicore'
                }
            },
        },
        mounted() {
            log("register 加载");
        },
    };
</script>

<style lang="scss" scoped>
    .agreement {
        color: #FDBA04;
        font-size: 10px;
    }

    .img-icon {
        height: 20px;
    }

    .user-account-checkbox {
        color: #999999 !important;
        font-size: 13px;
    }

    .login {
        color: #FDBA04;
    }
</style>