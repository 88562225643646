<template>
    <div class="preheat-container">
        <div class="preheat-control">
            <span class="preheat-status" @click="togglePreheatClick">{{isStartPreheat?'Press to Stop':'Press to Start'}}</span>
            <span class="preheat-control-btn" :class="[isStartPreheat?'start':'stop']"></span>
        </div>
        <div class="preheat-options mt-16">
            <div class="preheat-item" :class="{selected: currentItem === 1}" @click="normalPreheatClick">
                <div class="preheat-normal-img"
                     :class="[currentItem !== 1 ? 'normal':'selected']"></div>
                <div class="mt-8">Normal</div>
                <div class="mt-4 preheat-second">30s</div>
            </div>
            <div class="preheat-item" :class="{selected: currentItem === 2}" @click="economicalPreheatClick">
                <div class="preheat-economical-img" :class="[currentItem !== 2 ? 'normal':'selected']"></div>
                <div class="mt-8">Economical</div>
                <div class="mt-4 preheat-second">45s</div>
            </div>
            <div class="preheat-item" :class="{selected: currentItem === 3}" @click="rushPreheatClick">
                <div class="preheat-rush-img" :class="[currentItem !== 3 ? 'normal':'selected']"></div>
                <div class="mt-8">Rush</div>
                <div class="mt-4 preheat-second">15s</div>
            </div>
            <div class="preheat-item" :class="{selected: currentItem === 4}" @click="customizedPreheatClick">
                <div class="preheat-custom-img" :class="[currentItem !== 4 ? 'normal':'selected']"></div>
                <div class="mt-8">Customized</div>
                <div class="mt-4 preheat-second">{{preheatValue.preheatSecond}}s</div>
            </div>
        </div>
    </div>
</template>

<script>
    import bluetoothRepository, {
        PREHEAT_TYPE_ENTER_START,
        PREHEAT_TYPE_STOP
    } from "../repository/bluetooth/BluetoothRepository"
    import StoreType from "../vuex/StoreType"

    export default {
        name    : "PreheatContainer",
        props   : {
            preheat       : {
                type   : Object,
                default: () => {
                    return {
                        preheatTemperature: 0,
                        preheatSecond     : 0
                    }
                }
            },
            isStartPreheat: {
                type   : Boolean,
                default: false
            }
        },
        data() {
            return {
                currentItem: 4
            }
        },
        methods : {
            normalPreheatClick() {
                this.currentItem = 1
                this.$emit("preheatParamsChanged", this.currentItem)
            },
            economicalPreheatClick() {
                this.currentItem = 2
                this.$emit("preheatParamsChanged", this.currentItem)
            },
            rushPreheatClick() {
                this.currentItem = 3
                this.$emit("preheatParamsChanged", this.currentItem)
            },
            customizedPreheatClick() {
                this.currentItem = 4
                this.$emit("preheatParamsChanged", this.currentItem)
            },
            togglePreheatClick() {
                let temperature = this.preheat.preheatTemperature
                let second      = this.preheat.preheatSecond
                switch (this.currentItem) {
                    case 1:
                        temperature = 55
                        second      = 30
                        break
                    case 2:
                        temperature = 50
                        second      = 45
                        break
                    case 3:
                        temperature = 60
                        second      = 15
                        break
                }

                if (this.isStartPreheat) {
                    //非预热状态，,开始操作
                    bluetoothRepository.writePreheat(
                        PREHEAT_TYPE_STOP,
                        temperature,
                        second)
                } else {
                    //预热状态,停止操作
                    bluetoothRepository.writePreheat(
                        PREHEAT_TYPE_ENTER_START,
                        temperature,
                        second)
                }
            }
        },
        computed: {
            preheatValue() {
                this.currentItem = 4
                return this.preheat
            }
        }
    }
</script>

<style lang="scss" scoped>
    .preheat-container {
        color: #000000;
        font-size: 12px;
        display: flex;
        flex-direction: column;

        .preheat-control {
            display: flex;
            flex-direction: row;
            background: #333333;
            padding: 12px;
            border-radius: 8px;
            align-items: center;
            color: #000000;

            .preheat-status {
                flex: 1;
            }

            .preheat-control-btn {
                width: 25px;
                height: 25px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                &.start {
                    @include bg-image("../assets/preheat/preheat-started");
                }

                &.stop {
                    @include bg-image("../assets/preheat/preheat-stop");
                }
            }

        }

        .preheat-options {
            display: flex;
            flex-direction: row;

            .preheat-item {
                display: flex;
                padding: 10px 0;
                flex: 1;
                margin: 0 4px;
                flex-basis: 10px;
                flex-direction: column;
                align-content: center;
                align-items: center;
                background: #333333;
                border-radius: 16px;

                .preheat-normal-img {
                    width: 20px;
                    height: 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    &.normal {
                        @include bg-image("../assets/preheat/Normal_n");
                    }

                    &.selected {
                        @include bg-image("../assets/preheat/Normal_s");
                    }
                }

                .preheat-economical-img {
                    width: 20px;
                    height: 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    &.normal {
                        @include bg-image("../assets/preheat/Economical_n");
                    }

                    &.selected {
                        @include bg-image("../assets/preheat/Economical_s");
                    }
                }

                .preheat-rush-img {
                    width: 20px;
                    height: 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    &.normal {
                        @include bg-image("../assets/preheat/Rush_n");
                    }

                    &.selected {
                        @include bg-image("../assets/preheat/Rush_s");
                    }
                }

                .preheat-custom-img {
                    width: 20px;
                    height: 30px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    &.normal {
                        @include bg-image("../assets/preheat/Customize_n");
                    }

                    &.selected {
                        @include bg-image("../assets/preheat/Customize_s");
                    }
                }

                .preheat-second {
                    font-size: 10px;
                }
            }
        }
    }
</style>