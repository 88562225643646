<template>
    <div class="target-container">
        <div class="target-duration-container">
            <canvas ref="targetDurationRef" width="120" height="120" class=""></canvas>
            <span class="target-info mt-8">Target time: {{this.cartridgeInfoServer.daySeconds}}s</span>
        </div>

        <div class="target-input-container">
            <canvas ref="targetInputRef" width="120" height="120"></canvas>
            <span class="target-info mt-8">Target dosage: {{targetDosage}}{{this.unit}}</span>
        </div>

        <span v-show="false">{{todayDosage}}</span>
    </div>
</template>

<script>
    import RingView from '../repository/RingView'
    import {initCanvasRatio} from "../util/util"
    import {INGREDIRENT_TYPE_NICOTINE, INGREDIRENT_TYPE_SUPPLEMENT} from "../repository/bluetooth/BluetoothRepository"

    export default {
        name : "DurationRingView",
        props: {
            todaySeconds       : {
                type   : Number,
                default: 0
            },
            targetSeconds      : {
                type   : Number,
                default: 0
            },
            todayMg            : {
                type   : Number,
                default: 0
            },
            targetMg           : {
                type   : Number,
                default: 0
            },
            smokeData          : {
                type   : Object,
                default: () => {
                }
            },
            cartridgeInfoServer: {
                type   : Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                targetDuration: null,
                targetSmokeMg : null,
                unit          : 'mg'
            }
        },

        methods: {},

        mounted() {
            const durationCanvas = this.$refs.targetDurationRef
            const inputCanvas    = this.$refs.targetInputRef


            if (durationCanvas) {
                const canvasObj = initCanvasRatio(durationCanvas)
                if (!this.targetDuration) {
                    this.targetDuration = new RingView(canvasObj.canvas, canvasObj.width, canvasObj.height, {targetText: 'Total dosage'})
                }
                this.targetDuration.draw()
            }

            if (inputCanvas) {
                const canvasObj = initCanvasRatio(inputCanvas)
                if (!this.targetSmokeMg) {
                    this.targetSmokeMg = new RingView(canvasObj.canvas, canvasObj.width, canvasObj.height, {targetText: 'Total dosage'})
                }
                this.targetSmokeMg.draw()
            }
        },
        computed: {
            todaySmokeDuration() {
                if (this.smokeData) {
                    const todaySmokeDuration = this.smokeData.todaySmokeDuration
                    if (todaySmokeDuration) {
                        if (this.targetDuration) {
                            this.targetDuration.setValue(`${todaySmokeDuration}s`, todaySmokeDuration, this.cartridgeInfoServer.daySeconds)
                            return todaySmokeDuration
                        }
                    }
                }
                return 0
            },

            cartridgeInfo() {
                return this.$store.state.deviceInfo.cartridgeInfo
            },
            secondDosage() {
                const dose               = this.cartridgeInfoServer.dose
                const formulaList        = this.cartridgeInfoServer.formulaList
                if (!formulaList || formulaList.length === 0) {
                    return 0
                }
                const mainProportion = formulaList[0].proportion
                const status         = formulaList[0].status
                const type           = this.cartridgeInfo.ingredirentType
                let secondDosage     = 0
                if (type === INGREDIRENT_TYPE_SUPPLEMENT || type === INGREDIRENT_TYPE_NICOTINE) {
                    secondDosage = dose * 1000 / 0.8 / 450 * dose * mainProportion
                    secondDosage = secondDosage * 1000
                    this.unit    = "ug"
                } else {
                    secondDosage = dose * 1000 / 450 * dose * mainProportion
                }

                if (status === 0) {
                    secondDosage = secondDosage / 100 / 100
                } else {
                    secondDosage = secondDosage / 10000 / 100
                }

                return secondDosage
            },

            targetDosage() {
                return `${(this.cartridgeInfoServer.daySeconds * this.secondDosage).toFixed(2)}`
            },

            todayDosage() {
                if (!this.todaySmokeDuration) {
                    return 0
                }
                const todaySmokeDosage  = this.todaySmokeDuration * this.secondDosage
                const targetSmokeDosage = this.targetDosage
                if (this.targetSmokeMg) {
                    this.targetSmokeMg.setValue(`${todaySmokeDosage.toFixed(2)}${this.unit}`, todaySmokeDosage, targetSmokeDosage)
                    return todaySmokeDosage
                }
                return 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    .target-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;

        .target-duration-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
        }

        .target-input-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
        }
    }

    .target-info {
        color: #000000;
        font-size: 10px;
    }
</style>