<template>
    <div class="temperature-mode-container">
        <div class="mode-name-container">
            <span class="back" @click="closeTemperatureSetting"></span> <span
                class="mode-name">Temperature mode setting</span>
        </div>
        <div ref="temperatureModeContainer" class="mt-32">
            <canvas ref="temperatureCanvas" :width="canvasWidth" height="240px" class="diy-mode-item">

            </canvas>

        </div>
        <div v-for="(item, index) in groupTemperatureMode" :key="index" class="mt-20">
            <div class=" diy-mode-container">
                <span @click="modifyTemperatureMode(i)" class="diy-mode-item" v-for="(temperatureMode,i) in item"
                      :class="temperatureMode.name === currentTemperatureMode.name ? `selected`: `normal`"
                >{{temperatureMode.name}}</span>
            </div>
        </div>
    </div>

</template>

<script>
    import TemperatureGraphView from "../repository/TemperatureGraphView"
    import bluetoothRepository from "../repository/bluetooth/BluetoothRepository"
    import log from "../repository/log/log"

    export default {
        name    : "TemperatureSetting",
        props   : {
            isShow         : {
                type   : Boolean,
                default: false
            },
            showLine       : {
                type   : Boolean,
                default: false
            },
            temperatureData: {
                type   : Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                temperatureGraphView: null,
                canvasWidth         : "300px"
            }
        },
        methods : {
            modifyTemperatureMode(selectIndex) {
                const secondTemperature = this.diyTemperatureMode[selectIndex]
                bluetoothRepository.writeTemperatureMode(secondTemperature)
            },
            closeTemperatureSetting() {
                this.$emit("onCloseTemperatureSetting")
            }
        },
        watch   : {
            isShowChanged(newValue, oldValue) {
                if (newValue) {

                }
            },
            diyTemperatureMode(newValue, oldValue) {
                if (this.temperatureGraphView) {
                    this.temperatureGraphView.init({
                        showLine: this.showLine,
                        data    : newValue.secondTemperature
                    })
                    this.temperatureGraphView.draw()
                }
            }
        },
        computed: {
            isShowChanged() {
                return this.isShow
            },
            diyTemperatureMode() {
                return this.$store.state.diyTemperatureMode
            },

            currentTemperatureMode() {
                if (this.temperatureGraphView) {
                    this.temperatureGraphView.init({
                        showLine: this.showLine,
                        data    : this.temperatureData.secondTemperature
                    })
                    this.temperatureGraphView.draw()
                }
                return this.temperatureData
            },
            groupTemperatureMode() {
                const diyTemperatureMode   = this.$store.state.diyTemperatureMode
                const groupTemperatureMode = []
                let groupData              = []
                diyTemperatureMode.secondTemperature.forEach((item, index) => {
                    groupData.push(item)
                    if (groupData.length % 4 === 0) {
                        groupTemperatureMode.push(groupData)
                        groupData = []
                    }
                })

                return groupTemperatureMode
            }
        },
        mounted() {
            log("setting mounted")
            const canvas = this.$refs.temperatureCanvas
            if (!this.temperatureGraphView) {
                const temperatureModeContainer           = this.$refs.temperatureModeContainer
                const temperatureModeContainerWidthValue = temperatureModeContainer.offsetWidth
                this.canvasWidth                         = `${temperatureModeContainerWidthValue}px`
                this.$nextTick(() => {
                    const temperatureModeContainer           = this.$refs.temperatureModeContainer
                    const temperatureModeContainerWidthValue = temperatureModeContainer.offsetWidth
                    this.temperatureGraphView                = new TemperatureGraphView(canvas.getContext('2d'), temperatureModeContainerWidthValue, canvas.height)
                    this.temperatureGraphView.init({
                        showLine: this.showLine,
                        data    : this.temperatureData.secondTemperature
                    })
                    this.temperatureGraphView.draw()
                })
            } else {
                this.temperatureGraphView.init({
                    showLine: this.showLine,
                    data    : this.temperatureData.secondTemperature
                })
                this.temperatureGraphView.draw()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .temperature-mode-container {
        display: flex;
        padding: 22px;
        flex-direction: column;
        background-color: #333333;

        .mode-name-container {
            display: flex;
            color: #F6F6F6;
            flex-direction: row;
            align-items: center;

            .back {
                width: 3px;
                height: 14px;
                padding: 4px;
                margin-left: 8px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                @include bg-image("../assets/back");
            }


            .mode-name {
                flex: 1;
                text-align: center;
                margin-right: 26px;
                font-size: 17px;
            }
        }

        .diyTemperature {

        }

        .diy-mode-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .diy-mode-item {
                flex: 1;
                font-size: 8px;
                text-align: center;
                margin: 2px;
                padding-top: 12px;
                padding-bottom: 12px;
                color: #F6F6F6;
                border-radius: 16px;

                &.selected {
                    color: #ED6C0F;
                    border: 1px solid #ED6C0F;
                }

                &.normal {
                    color: #F6F6F6;
                    border: 1px solid #F6F6F6;
                }
            }
        }
    }

</style>