<template>
    <div class="mine-center">
        <div class="mine-bg" @click="profileClick">
            <van-image
                    class="mt-80"
                    width="80px"
                    height="80px"
                    fit="cover"
                    :src="userInfo.picture"
                    round/>
            <span class="mt-20">{{userInfo.nickName}}</span>
        </div>

        <div class="mine-menu-container">
            <div class="mine-menu" v-for="(item,i) in menuItem" @click="itemClick(i)">
                <span class="item">{{item}}</span> <span class="right-arrow"></span>
            </div>
        </div>


        <div class="toolbar pt-16 pb-16">
            <div class="back-container" @click="backClick">
                <span class="back"></span>
            </div>
            <span class="title">Mine</span>
            <div class="right-menu">
                <span class="right" :class="hasMessage?`selected`:``" @click="onMessageClick"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import userRepository from '../repository/store/UserRepository'

    export default {
        name    : "Mine",
        data() {
            return {
                menuItem  : [
                    "My devices",
                    "My Record",
                    "FAQ",
                    "More",
                    "About Us"
                ],
                hasMessage: false
            }
        },
        methods : {
            backClick() {
                this.$router.back()
            },
            itemClick(index) {
                switch (index) {
                    case 0:             //My Devices
                        this.$router.push("MyDevices")
                        break
                    case 1:             //My Record
                        break
                    case 2:             //FAQ
                        this.$router.push("MineFAQ")
                        break
                    case 3:             //More
                        this.$router.push("More")
                        break
                    case 4:             //About Us
                        this.$router.push({
                            name: "Web", params: {
                                url  : 'https://supplement.unicoreus.com/aboutUser',
                                title: 'About Us'
                            }
                        })
                        break
                }
            },
            profileClick() {
                this.$router.push("Profile")
            },
            onMessageClick() {
                this.$router.push("Message")
            },
            async refreshUserInfo() {
                await userRepository.getUserInfo()
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo
            }
        },
        activated() {
            this.refreshUserInfo()

            userRepository.isUnreadMessage()
                .then(data => {
                    if (data > 0) {
                        this.hasMessage = true
                    }
                })
        }
    }
</script>

<style lang="scss" scoped>
    .mine-center {
        display: flex;
        flex-direction: column;

        .mine-bg {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            color: #FCF5F5;
            height: 226px;
            .avatar {
                margin-top: 20px;
            }
        }

        .mine-menu-container {
            color: #000000;
            background: #FCF5F5;

            .mine-menu {
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 0.5px solid #666666;
                box-sizing: border-box;
                padding: 16px;

                .item {
                    flex: 1;
                }

                .right-arrow {
                    width: 7px;
                    height: 12px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    @include bg-image("../assets/right-arrow");
                }
            }
        }

        .filed-background {
            background-color: #00000000;
        }

    }

    .right {
        width: 17px;
        height: 17px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        margin-right: 8px;
        @include bg-image("../assets/message");

        &.selected {
            @include bg-image("../assets/message_s");
        }
    }
</style>