<template>
    <div class="faq-item" @click="onItemClick(faqItem)">
        <span class="item">{{faqItem.title}}</span> <span class="right-arrow"></span>
    </div>
</template>

<script>
    export default {
        name   : "FAQItem",
        props  : {
            faqItem: {
                type   : Object,
                default: () => {

                }
            }
        },
        methods: {
            onItemClick(id) {
                this.$router.push({name: 'FAQDetail', params: {faqItem: this.faqItem}})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .faq-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 0.5px solid #666666;
        box-sizing: border-box;
        padding: 16px;

        .item {
            flex: 1;
            color: #000000;
        }

        .right-arrow {
            width: 7px;
            height: 12px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            @include bg-image("../assets/right-arrow");
        }
    }
</style>