<template>
    <div class="temperature-mode-container" ref="temperatureModeContainer">
        <div class="mode-name-container">
            <span class="mode-name">{{temperatureMode.name}}</span> <span class="setting-mode-icon"
                                                                          @click="showTemperatureSetting"></span>
        </div>
        <span class="mode-tips  mt-8">This thing should only exist in the heaven, such as walking in the clouds.</span>
        <canvas ref="temperatureCanvas" :width="canvasWidth" height="130px" v-show="$store.state.deviceStatus.isInsert">

        </canvas>

    </div>

</template>

<script>
    import TemperatureGraphView from "../repository/TemperatureGraphView"
    import log from "../repository/log/log"

    export default {
        name    : "TemperatureMode",
        props   : {
            isShow         : {
                type   : Boolean,
                default: false
            },
            isFire         : {
                type   : Boolean,
                default: false
            },
            showLine       : {
                type   : Boolean,
                default: false
            },
            temperatureData: {
                type   : Object,
                default: () => {
                    return {}
                }
            }
        },
        watch   : {
            isShowChanged(newValue, oldValue) {
                if (newValue) {
                    const canvas = this.$refs.temperatureCanvas
                    if (!this.temperatureGraphView) {
                        const temperatureModeContainer           = this.$refs.temperatureModeContainer
                        const temperatureModeContainerWidthValue = temperatureModeContainer.offsetWidth
                        this.canvasWidth                         = `${temperatureModeContainerWidthValue}px`
                        this.$nextTick(() => {
                            const temperatureModeContainer           = this.$refs.temperatureModeContainer
                            const temperatureModeContainerWidthValue = temperatureModeContainer.offsetWidth
                            this.temperatureGraphView                = new TemperatureGraphView(canvas.getContext('2d'), temperatureModeContainerWidthValue, canvas.height)
                            this.temperatureGraphView.init({
                                showLine: this.showLine,
                                data    : this.temperatureData.secondTemperature
                            })
                            this.temperatureGraphView.draw()
                        })
                    } else {
                        this.temperatureGraphView.draw()
                    }
                }

            },
            isFireChanged(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        this.startSwipe()
                    } else {
                        this.stopSwipe()
                    }
                }
            },
            currentTemperatureMode(newValue, oldValue) {
                if (this.temperatureGraphView) {
                    if (newValue.secondTemperature) {
                        this.temperatureGraphView.init({
                            showLine: this.showLine,
                            data    : newValue.secondTemperature
                        })
                        this.temperatureGraphView.draw()
                    }
                }
            }
        },
        computed: {
            isShowChanged() {
                return this.isShow
            },
            isFireChanged() {
                return this.isFire
            },

            temperatureMode() {
                return this.$store.state.temperatureMode
            },
            currentTemperatureMode() {

                return this.temperatureData
            },
        },
        data() {
            return {
                temperatureGraphView        : null,
                canvasWidth                 : "400px",
                isShowTemperatureModeSetting: false
            }
        },
        methods : {
            startSwipe() {
                if (this.temperatureGraphView) {
                    this.temperatureGraphView.startSwipe()
                }
            },
            stopSwipe() {
                if (this.temperatureGraphView) {
                    this.temperatureGraphView.stopSwipe()
                }
            },

            showTemperatureSetting() {
                this.$emit("showTemperatureSettingChanged", true)
            }
        },
        mounted() {
            if (this.temperatureGraphView) {
                this.temperatureGraphView.draw()
            }
        },

    }
</script>

<style lang="scss" scoped>
    .temperature-mode-container {
        display: flex;
        padding: 22px;
        flex-direction: column;
        background-color: #333333;
        border-radius: 16px;

        .mode-name-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .mode-name {
                flex: 1;
                font-size: 17px;
                color: #ED6C0F;
            }

            .setting-mode-icon {
                width: 18px;
                height: 14px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                @include bg-image("../assets/setting-mode-icon");
            }
        }


        .mode-tips {
            font-size: 10px;
            color: #F6F6F6;
        }
    }
</style>