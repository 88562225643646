<template>
    <div class="forget-password-container">
        <van-steps :active="active" active-icon="success">
            <van-step >Enter E-mail</van-step>
            <van-step>Verification Code</van-step>
            <van-step>Reset password</van-step>
        </van-steps>

        <div class="ml-16 mr-16 tips" v-html="step[active]">
            <p>Please reset your login password. After the setting is successful, the original password will be
                invalid.</p>
            <p>Please use the new password to log in to the APP.</p>
        </div>


        <div class="mt-48 user-account-input" v-if="active === 0">
            <van-field clsas="input-text-color"
                       type="text"
                       v-model="formData.email"
                       placeholder="Email"
                       size="20"
                       clearable/>
        </div>

        <div v-if="active === 1">
            <van-password-input
                    :value="this.formData.captcha"
                    :length="6"
                    :mask="false"
                    :focused="showKeyboard"
                    @focus="showKeyboard = true"
            />
            <!-- 数字键盘 -->
            <van-number-keyboard
                    :show="showKeyboard"
                    @input="onInput"
                    @delete="onDelete"
                    :transition="true"
                    @blur="showKeyboard = false"
            />
        </div>


        <div v-if="active === 2">

            <div class="mt-13 user-account-input">
                <van-field v-model="formData.newPassword" placeholder="Password"/>
            </div>
            <div class="mt-13 user-account-input">
                <van-field v-model="formData.confirmPassword" placeholder="Confirm password"/>
            </div>

        </div>

        <p v-if="active === 1" class="ml-16 mr-16 resend-container">
            Not received? <span class="resend" @click="resend">Resend</span>
        </p>

        <div class="ml-16 mr-16" v-if="active === 0 || active === 2">
            <van-button class="mt-16 center-btn user-account-btn" block round type="primary" @click="next">Next
            </van-button>
        </div>


        <div class="toolbar pt-16 pb-16">
            <div class="back-container" @click="backClick">
                <span class="back"></span>
            </div>
            <span class="title">Reset password</span>
            <div class="right-menu"></div>
        </div>
    </div>
</template>

<script>
    import rule from "../rule/rule"
    import userRepository from "../repository/store/UserRepository"

    export default {
        name   : "ForgetPassword",
        data() {
            return {
                formData    : {
                    email          : '',
                    captcha        : '',
                    newPassword    : '',
                    confirmPassword: ''
                },
                showKeyboard: true,
                active      : 0,
                step        : [
                    "<p>Enter the email address you used when you joined and we' ll send you verification code to reset your\n" +
                    "                password.</p>\n" +
                    "            <p>For security reasons, we do NOT store your password.</p>\n" +
                    "            <p>So rest assured that we will never send your password via email.</p>",
                    "            <p>We have sent an email with a verification code to your mailbox.Please enter the verification code you received.</p>\n" +
                    "            <p>The verification code is valid for 30 minutes.</p>",
                    "            <p>Please reset your login password. After the setting is successful, the original password will be\n" +
                    "                invalid.</p>\n" +
                    "            <p>Please use the new password to log in to the APP.</p>"
                ]
            }
        },
        methods: {
            backClick() {
                if (this.active === 2) {
                    this.formData.newPassword     = ''
                    this.formData.confirmPassword = ''
                    this.active -= 1
                    return
                } else if (this.active === 1) {
                    this.formData.captcha = ''
                    this.active -= 1
                    return
                }
                this.$router.back()
            },
            next() {
                if (this.active === 0) {
                    if (!rule.email.validator(this.formData.email)) {
                        return
                    }
                    userRepository.sendVerifyEmail(this.formData.email)
                        .then(data => {
                            this.active += 1
                        })
                } else if (this.active === 1) {
                    userRepository.verifyCode(this.formData.email, this.formData.captcha)
                        .then(data => {
                            this.active += 1
                        })
                } else {
                    if (!rule.confirmPassword.validator(this.formData.newPassword, this.formData.confirmPassword)) {
                        return
                    }
                    userRepository.resetPassword(this.formData.email, this.formData.captcha, this.formData.newPassword)
                        .then(data => {
                            this.$router.replace("Login")
                        })
                }
            },
            resend() {
                userRepository.sendVerifyEmail(this.formData.email)
                    .then(data => {
                        toast("send successfully")
                    })
            },
            onInput(key) {
                this.formData.captcha = (this.formData.captcha + key).slice(0, 6)
                if (this.formData.captcha.length === 6) {
                    this.next()
                }
            },
            onDelete() {
                this.formData.captcha = this.formData.captcha.slice(0, this.formData.captcha.length - 1);
            },
        }
    }
</script>

<style lang="scss" scoped>

    .forget-password-container {
        margin-top: 56px;

        .tips {
            font-size: 14px;
            color: #000000;
        }

        .user-account-input {
            border-radius: 64px;
            margin-left: 16px;
            margin-right: 16px;
            border: 1px solid #999999;

            &:focus {
                border-radius: 64px;
                border: 1px solid #FDBA04;
            }

            .van-cell {
                background-color: transparent;
                color: #000000 !important;
            }
        }

        .resend-container {
            color: #000000;
            font-size: 13px;
            margin-left: 16px;
            margin-top: 8px;

            .resend {
                color: #FDBA04;
            }
        }
    }
    .van-icon {
        color: #FDBA04 !important;
    }

</style>