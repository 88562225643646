<template>
    <div class="more-container">
        <div :class="logoIcon"></div>
        <span class="version">{{version}}</span>

        <div class="mine-menu-container">
            <div class="mine-menu" v-for="(item,i) in menuItem" @click="itemClick(i)">
                <span class="item">{{item.left}}</span> <span class="item-right">{{item.right}}</span> <span
                    class="right-arrow"></span>
            </div>
        </div>

        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
                <span class="back" @click="backClick"></span>
            </div>
            <span class="title">More</span>
            <div class="right-menu"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name    : "More",
        data() {
            return {}
        },
        methods : {
            backClick() {
                this.$router.back()
            },
            itemClick(index) {
                switch (index) {
                    case 0:             //My Record
                        break
                    case 1:             //FAQ
                        break
                    case 2:             //More
                        this.$router.push("More")
                        break
                    case 3:             //About Us
                        break
                }
            }
        },
        computed: {
            logoIcon() {
                if (location.hostname === 'dime.unicoreus.com') {
                    return 'logo-dime'
                } else if (location.hostname === 'app.unicoreus.com') {
                    return 'logo-unicore'
                }else {
                    return 'logo-unicore'
                }
            },
            menuItem() {
                const cartridgeInfo = this.$store.state.deviceInfo
                return [
                    {left: "Device Version", right: cartridgeInfo.version},
                    {left: "Latest sync time", right: cartridgeInfo.syncTime}
                ]
            },
            version() {
                if (location.hostname === 'app.unicoreus.com') {
                    return "Unicore v1.0.0"
                } else if (location.hostname === 'dime.unicoreus.com') {
                    return "Dime v1.0.0"
                }else {
                    return "Unicore v1.0.0"
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .more-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #F6F6F6;


        .version {
            color: #999999;
            margin-bottom: 30px;
            font-size: 13px;
        }

        .mine-menu-container {
            color: #000000;
            width: 100%;

            .mine-menu {
                display: flex;
                flex-direction: row;
                background: #FCF5F5;
                align-items: center;
                padding: 16px;

                .item {
                    flex: 1;
                }

                .item-right {
                    color: #999999;
                    margin-right: 8px;
                }

                .right-arrow {
                    width: 7px;
                    height: 12px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    @include bg-image("../assets/right-arrow");
                }
            }
        }
    }


</style>