<template>
    <div>
        <div class="ingredient-detail pb-32 ">
            <div class="pie-legend-data ">
                <div ref="pieData" class="pie-data" v-show="isInsert"></div>
            </div>
            <van-tabs v-model="tabActive" :animated="true" background="#FCF5F5" class="mt-48">
                <van-tab title="Regulatory Testing">
                    <div class="ingredient-info">
                        <div class="item-container pl-16 pr-16">
                            <div class="detail-item">
                                <span class="key-name">Sample ID</span>
                                <span v-show="isInsert">{{cartridgeInfo.sampleNumber || `N/A`}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Sample Name</span>
                                <span v-show="isInsert">{{cartridgeInfo.flavor || `N/A`}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Batch Id</span>
                                <span v-show="isInsert">{{cartridgeInfo.sampleBatchNumber|| `N/A`}}</span>
                            </div>
                        </div>

                        <div class="item-container pl-16 pr-16 mt-10">
                            <div v-for="report in cartridgeInfo.addReport" :key="report.id" class="detail-item">
                                <span class="key-name">{{report.reportName}}</span>
                                <span :class="report.passOrNot === 0 ? `pass`:`nopass`"></span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">More Details</span>
                                <span style="color: #ed6a0c;" v-show="isInsert" @click="onReportClicked">{{cartridgeInfo.reportName}}</span>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="Basic Info" >
                    <div class="ingredient-info  cartridge-info-background ">
                        <span style="margin-left: 23px;margin-top: 13px;">Cartridge information</span>
                        <div class="ingredient-info-top mt-10 ml-16 mr-16">
                            <div class="brand-info">
                                <van-image
                                        class="brand-img"
                                        width="45px"
                                        height="45px"
                                        fit="cover"
                                        v-show="isInsert"
                                        :src="cartridgeInfo.companyIcon"
                                        round/>
                                <div class="brand-name ml-11" v-show="isInsert">
                                    <div>{{cartridgeInfo.brandName}}</div>
                                    <div class="mt-10 flavor-name">{{cartridgeInfo.flavor}}</div>
                                </div>
                            </div>
                            <div class="pod-id-info pt-20">
                                Cartridge ID : <span v-show="isInsert">{{cartridgeInfo.smokeRecordNumber}}</span>
                            </div>
                        </div>
                        <div class="item-container ml-16 mr-16">
                            <div class="detail-item">
                                <span class="key-name">Capacity</span>
                                <span v-show="isInsert">{{cartridgeInfo.doses}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Made In</span>
                                <span v-show="isInsert">{{cartridgeInfo.placeOfOrigin}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Manufacturer</span>
                                <span v-show="isInsert">{{cartridgeInfo.madeinName}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Production Date</span>
                                <span v-show="isInsert">{{cartridgeInfo.createTime}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Production Batch</span>
                                <span v-show="isInsert">{{cartridgeInfo.productionBatch}}</span>
                            </div>
                            <div class="detail-item">
                                <span class="key-name">Actived</span>
                                <span v-show="isInsert">{{cartridgeInfo.activationTime}}</span>
                            </div>
                        </div>

                        <span v-show="false">{{cartridgeInfoServer.createTime}}}</span>

                    </div>
                </van-tab>
            </van-tabs>

        </div>
        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
                <span class="back" @click="backClick"></span>
            </div>
            <span class="title">Cartridge Info</span>
            <div class="right-menu"></div>
        </div>
    </div>
</template>

<script>
    const echarts = require('echarts');
    export default {
        name    : "IngredientInfo",
        props   : {},
        data() {
            return {
                tabActive    : 1,
                pieChart     : null,
                options      : {
                    legend: {
                        padding  : 5,
                        itemGap  : 15,
                        orient   : "vertical",
                        right    : "right",
                        textStyle: {
                            color: '#000000'
                        },
                        data     : []
                    },
                    series: {
                        type : "pie",
                        label: {
                            normal: {
                                show     : true,
                                position : 'outside',
                                formatter: '{b}/{d}%',//模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比

                                textStyle: {
                                    align     : 'center',
                                    baseline  : 'middle',
                                    fontFamily: '微软雅黑',
                                    fontSize  : 8,
                                    fontWeight: 'bolder'
                                }
                            },
                        },
                        data : []
                    },
                    color : [
                        "#ED6C0F",
                        "#395463",
                        "#6F7B86",
                        "#755F72",
                        "#777777",
                        "#9B5959",
                        "#B26D67",
                        "#FA9A54"
                    ]
                },
                cartridgeInfo: this.$route.params.cartridgeInfo
            }
        },
        methods : {
            renderPieData(cartridgeInfoServer) {
                if (!this.pieData) {
                    return
                }
                this._handleData(cartridgeInfoServer)

                this.pieData.setOption(this.options)
            },

            _handleData(cartridgeInfoServer) {
                const pieData     = []
                const legendData  = []
                const formulaList = cartridgeInfoServer.formulaList
                if (formulaList) {
                    formulaList.forEach((item, index) => {
                        let proportion = 0
                        if (item.status === 0) {
                            proportion = item.proportion / 100
                        } else {
                            proportion = item.proportion / 10000
                        }
                        legendData.push(item.component)
                        pieData.push({
                            name : item.component,
                            value: proportion
                        })
                        this.options.series.data = pieData
                        this.options.legend.data = legendData
                    })
                }
            },
            onReportClicked() {
                if (this.cartridgeInfo.reportLink === '') {
                    return
                }
                this.$router.push({
                    name  : "Web",
                    params: {
                        url  : this.cartridgeInfo.reportLink.replace("http://13.57.48.78:8080", `https://${location.hostname}`),
                        title: this.cartridgeInfo.reportName
                    }
                })
            },
            backClick() {
                this.$router.back()
            }
        },
        watch   : {
            isInsert(newValue, oldValue) {
                if (!newValue) {
                    this.options.series.data = []
                    this.options.legend.data = []
                    this.pieData.setOption(this.options)
                }
            }
        },
        computed: {
            isInsert() {
                return this.$store.state.deviceStatus.isInsert
            },
            cartridgeInfoServer() {
                let cartridgeInfoServer = this.$store.state.cartridgeInfoServer;
                this.cartridgeInfo      = cartridgeInfoServer
                this.renderPieData(cartridgeInfoServer)
                return cartridgeInfoServer
            }
        },
        activated() {
            this.pieData = echarts.init(this.$refs.pieData)
            this.pieData.resize('100%', '200px')
            this.renderPieData(this.cartridgeInfo)
        }
    }
</script>

<style lang="scss" scoped>
    .ingredient-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;

        .pie-legend-data {
            display: flex;
            margin-top: 56px;
            flex-direction: column;
            height: 250px;

            .pie-data {
                flex: 1;
            }
        }

        .regulatory {
            display: flex;
            flex-direction: column;
            flex: 3;
            color: #000000;
        }

        .ingredient-info {
            display: flex;
            flex-direction: column;
            flex: 3;
            color: #000000;

            .ingredient-info-top {
                border-radius: 16px;
                padding: 13px;
                background: #FCF5F5;

                .brand-info {
                    display: flex;
                    flex-direction: row;

                    .brand-img {
                    }

                    .brand-name {
                        flex: 5;
                        font-size: 16px;

                        .flavor-name {
                            font-size: 13px;
                        }
                    }
                }

                .pod-id-info {
                    background: #FCF5F5;
                    color: #999999;
                    font-size: 10px;
                }
            }

            .item-container {
                background: #FCF5F5;
                display: flex;
                flex-direction: column;

                .detail-item {
                    display: flex;
                    flex-direction: row;
                    font-size: 13px;
                    padding-top: 18px;
                    padding-bottom: 18px;
                    position: relative;

                    .key-name {
                        flex: 1;
                        color: #999999;
                    }

                    .value-name {
                        flex: 4;
                        color: #F6F6F6;
                    }
                }

                .detail-item:not(:last-child) {
                    border-bottom: solid 1px #999999;
                }
            }

        }
    }

    .pass {
        width: 30px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include bg-image("../assets/pass");
    }

    .nopass {
        width: 30px;
        height: 25px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include bg-image("../assets/fail");
    }

    .divider {
        background: #FCF5F5;
        min-height: 10px;
    }

    .cartridge-info-background {
        background: #FCF5F5;
    }
</style>