import log from "../log/log"
import store from "../../vuex/AppStore"
import StoreType from "../../vuex/StoreType"

export const KEY_USER  = "user"
export const KEY_TOKEN = "token"

class StoreRepository {

    constructor() {
        try {
            this._user = JSON.parse(window.atob(localStorage.getItem(KEY_USER) || '{}'))
        } catch (e) {
            this.clearUser()
        }
        this._token = localStorage.getItem(KEY_TOKEN)
    }

    saveUser(user) {
        this.clearUser()
        this._user           = user
        const base64UserData = window.btoa(JSON.stringify(user))
        log(`保存用户数据${base64UserData}`)
        store.commit(StoreType.COMMIT_TYPE.COMMIT_USER_INFO, this._user)
    }

    getUser() {
        return this._user
    }

    get token() {
        return this._token
    }

    saveToken(token) {
        localStorage.setItem(KEY_TOKEN, token)
        this._token = token
    }

    getToken() {
        return this._token
    }

    clearUser() {
        localStorage.removeItem(KEY_USER)
    }

    clearAll() {
        this._user  = null
        this._token = null
        localStorage.clear()
    }
}

const storeRepository = new StoreRepository()
export default storeRepository