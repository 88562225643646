<template>
    <div class="formula-container mt-20 pt-10 pb-10 " v-show="isInsert">
        <formula-item v-for="item in cartridgeInfoServer.formulaList" class="mt-20 mb-20" :key="item.id"
                      :second="second"
                      :dose="cartridgeInfoServer.dose"
                      :max-proportion="cartridgeInfoServer.maxProportion"
                      :formula="item"/>
    </div>
</template>

<script>
    import FormulaItem from "./FormulaItem"

    export default {
        name      : "FormulaContainer",
        components: {
            FormulaItem
        },
        props     : {
            cartridgeInfoServer: {
                type   : Object,
                default: () => {
                }
            },
            isInsert           : {
                type   : Boolean,
                default: false
            },
            second             : {
                type   : Number,
                default: 0
            }
        }
    }
</script>

<style lang="scss" scoped>
    .formula-container {
        flex: 1;
        width: 100%;
        height: 100%;
        background: #FCF5F5;
    }


</style>