<template>
    <div class="cartridge-info">

        <div class="frame-layout">
            <van-image
                    class="statistics flavor-img"
                    width="141px"
                    height="141px"
                    fit="cover"
                    round
                    v-show="isInsert"
                    :src="doses.picture"
            />
            <canvas ref="canvas" :width="width" :height="height" class="statistics"
                    v-show="isInsert"></canvas>
            <van-image
                    class="statistics flavor-img cartridge-pull-img "
                    width="141px"
                    height="141px"
                    fit="cover"
                    round
                    v-show="!isInsert"
                    :src="require('../assets/svg/Animation.gif')"
            />
            <!--<canvas id="cartridgePullCanvas" :width="width" :height="height" v-show="!isInsert"></canvas>-->

        </div>
    </div>
</template>

<script>
    import log from "../repository/log/log";
    import {initCanvasRatio} from "../util/util"
    import {INGREDIRENT_TYPE_NICOTINE, INGREDIRENT_TYPE_SUPPLEMENT} from "../repository/bluetooth/BluetoothRepository"

    export default {
        name    : "SmokeProgress",
        props   : {
            width    : String,
            height   : String,
            puffCount: {
                default: 0.0,
                type   : Number,
            },
            smokeOil : {
                default: 0.0,
                type   : Number,
            },
            progress : {
                default: {
                    current     : 0,
                    end         : 360,
                    fireProgress: 0
                },
                type   : Object,
            },
            doses    : {
                default: {
                    secondInTake  : 0,
                    mainProportion: 0
                },
                type   : Object,
            },
            isFire   : Boolean,
            isInsert : Boolean
        },
        data() {
            return {
                lastRefreshTime: 0,
                isFireBefore   : false,
                viewWidth      : 166,
                viewHeight     : 166
            };
        },
        methods : {
            start() {
                const canvasEl = this.$refs.canvas;
                if (!canvasEl.getContext) {
                    return;
                }

                const canvas                 = canvasEl.getContext("2d");
                const height                 = this.viewWidth
                const width                  = this.viewHeight
                canvas.imageSmoothingEnabled = true

                canvas.clearRect(0, 0, width, height);
                const outerArcLinearGradient1 = canvas.createLinearGradient(
                    0,
                    0,
                    width,
                    height
                );
                outerArcLinearGradient1.addColorStop(0, "#FFEADA");
                outerArcLinearGradient1.addColorStop(0.33, "#EAD919");
                outerArcLinearGradient1.addColorStop(0.66, "#FF9D11");
                outerArcLinearGradient1.addColorStop(1, "#E75239");

                const outerArcLinearGradient2 = canvas.createLinearGradient(
                    0,
                    0,
                    width,
                    height
                );
                outerArcLinearGradient2.addColorStop(0, "#E75239");
                outerArcLinearGradient2.addColorStop(0.25, "#FFF100");
                outerArcLinearGradient2.addColorStop(0.5, "#AAD919");
                outerArcLinearGradient2.addColorStop(1, "#23ACC7");

                canvas.lineWidth = 4;
                canvas.fillStyle = outerArcLinearGradient1;
                // canvas.fillRect(0, 0, width, height)
                const startAngle = -Math.PI / 2;
                const R          = width / 2 - canvas.lineWidth - 3;
                const start      = this.progress.current;
                if (start > 360) {
                    const angle = (Math.PI / 180.0) * (start % 360) - Math.PI / 2;

                    if ((start / 360) % 2 === 0) {
                        canvas.beginPath();
                        canvas.strokeStyle = outerArcLinearGradient2;
                        canvas.arc(width / 2, height / 2, R, startAngle, 2 * Math.PI);
                        canvas.stroke();

                        canvas.beginPath();
                        canvas.strokeStyle = outerArcLinearGradient1;
                        canvas.arc(width / 2, height / 2, R, startAngle, angle);
                        canvas.stroke();
                    } else {
                        canvas.beginPath();
                        canvas.strokeStyle = outerArcLinearGradient1;
                        canvas.arc(width / 2, height / 2, R, startAngle, 2 * Math.PI);
                        canvas.stroke();

                        canvas.beginPath();
                        canvas.strokeStyle = outerArcLinearGradient2;
                        canvas.arc(width / 2, height / 2, R, startAngle, angle);
                        canvas.stroke();
                    }
                    canvas.stroke();
                } else {
                    canvas.beginPath();
                    const angle        = (Math.PI / 180.0) * start - Math.PI / 2;
                    canvas.strokeStyle = outerArcLinearGradient1;
                    canvas.arc(width / 2, height / 2, R, startAngle, angle);
                    canvas.stroke();
                }

                const angle        = (Math.PI / 180.0) * (start % 360) - Math.PI / 2;
                const smallCircleX = height / 2 + Math.cos(angle) * R;
                const smallCircleY = width / 2 + Math.sin(angle) * R;

                canvas.beginPath();
                canvas.fillStyle = "white";
                canvas.lineWidth = 3;
                canvas.arc(smallCircleX, smallCircleY, 4, startAngle, 2 * Math.PI);
                canvas.fill();

                canvas.beginPath();
                canvas.lineWidth = 3;
                canvas.arc(smallCircleX, smallCircleY, 6, startAngle, 2 * Math.PI);
                canvas.stroke();

                //画draw
                canvas.textAlign    = "center";
                canvas.font         = "13px";
                canvas.fillStyle    = "#1A1A1A";
                canvas.textBaseline = "middle";
                canvas.fillText(`Draw ${this.smokeCount}`, width / 2, 34); //34 = 27 + canvas.lineWidth + 3 边距+两个圆 的画笔大小

                /*
                 * 按照公式计算吸入的剂量
                 */
                if (this.doseCount) {
                    let second = 0.0
                    let intake = 0.0
                    let unit   = "mg"
                    if (this.isFireBefore) {
                        if (this.doseCount.mainProportion !== 0.0) {
                            //这里需要发射时间更新出去
                            second = (Math.floor((((start / (1000 / 360)) * 16) / 1000) * 10) / 10).toFixed(1); //根据旋转的角度算出秒数
                            this.$emit("secondChanged", second)
                        }

                        const ingredirentType = this.$store.state.deviceInfo.cartridgeInfo.ingredirentType
                        if (ingredirentType === INGREDIRENT_TYPE_SUPPLEMENT || ingredirentType === INGREDIRENT_TYPE_NICOTINE) {
                            unit   = "ug"
                            intake = (this.doseCount.secondInTake * this.doseCount.mainProportion * 1000) * second
                        } else {
                            intake = (this.doseCount.secondInTake * this.doseCount.mainProportion) * second //根据   时间算出当前的摄入量
                        }

                        const formula         = this.doses.formulaList[0]
                        if (formula) {
                            const status = formula.staus
                            if (status === 0) { //主要成分
                                intake = (intake / 100 / 100).toFixed(2)
                            } else {
                                intake = (intake / 10000 / 100).toFixed(2)
                            }
                        }




                    }


                    //画吸入的剂量
                    canvas.save();
                    canvas.textAlign    = "center";
                    canvas.font         = "bold 20px serif";
                    canvas.fillStyle    = "#1A1A1A";
                    canvas.textBaseline = "middle";
                    canvas.fillText(`${intake}${unit}/${second}s`, width / 2, height / 2 + 7);
                    canvas.restore();
                }

                if (this.animateProgress != null) window.cancelAnimationFrame(this.animateProgress);
                this.animateProgress = requestAnimationFrame(() => {
                    // log(`current = ${this.progress.current}  end = ${this.progress.end}`)
                    if (this.progress.current < this.progress.end) {
                        this.progress.current += (1000 / 360);
                        this.start();
                    } else {
                        this.stop();
                    }
                });
            },

            stop() {
                if (this.animateProgress) {
                    window.cancelAnimationFrame(this.animateProgress)
                    this.animateProgress = null
                }
            },

            cartridgePullChanged(isInsert) {
                if (isInsert) {
                    this.progress.current = 0
                    this.progress.end     = 0
                    this.isFireBefore     = false
                    this.start()

                } else {
                }

            },
        },
        watch   : {
            smokeCount(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (!this.animateProgress) {
                        this.progress.end = this.progress.current
                    }
                    this.start()
                }
            },
            secondInTakeDose(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (!this.animateProgress) {
                        this.progress.end = this.progress.current
                    }
                    this.start()
                }
            },
            doseCount(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        if (newValue.mainProportion === 0 && isNaN(newValue.secondInTake)) {
                            return
                        }
                        if (!this.animateProgress) {
                            this.progress.end = this.progress.current
                        }
                        this.start()
                    }

                }
            },
            isFireChanged(newValue, oldValue) {
                if (!this.isFireBefore && newValue) {
                    this.isFireBefore = true
                }
            },
            isInsertChanged(newValue, oldValue) {
                if (newValue) {
                    this.$emit("secondChanged", 0)
                }

                this.isFireBefore = false
            }
        },
        computed: {
            smokeCount() {
                return this.puffCount;
            },
            doseCount() {
                return this.doses;
            },
            isFireChanged() {
                return this.isFire
            },
            isInsertChanged() {
                return this.isInsert
            }
        },
        mounted() {
            const canvasEl = this.$refs.canvas;
            if (canvasEl.getContext) {
                const canvasObj = initCanvasRatio(canvasEl)
                this.viewWidth  = canvasObj.width
                this.viewHeight = canvasObj.height
            }

            this.start();
        },
    };
</script>

<style lang="scss" scoped>
    .cartridge-info {
        height: 166px;
        width: 100%;
        position: relative;

        .frame-layout {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 166px;
        }

        .statistics {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 166px;
        }

        .flavor-img {
            padding: 10px;
            opacity: 0.4;
        }

        .cartridge-pull-img {
            padding: 10px;
            background: #999999;
        }
    }


    .draw {
        color: #999999;
    }
</style>