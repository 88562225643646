<template>
    <div class="bind-device-dialog-container">
        <div class="bind-device-tips" v-show="!isAlreadyBind">
            <p class="binding-device-title ml-14 m4-14">Binding Device</p>
            <p class="mt-31 binding-device-content ml-14 m4-14">We found that you are using this device for the first
                time, whether to bind it to your
                account?
            </p>
            <p class="binding-device-tips mt-10 ml-14 m4-14">Tips: Others can't connect it after you bond to your
                account.</p>
            <div class="mt-18 button-handle-container">
                <div class="later" @click="dismissDialog">No, Later</div>
                <div class="bind-device-button" @click="bindDevice">Yes, Binding it</div>
            </div>
        </div>

        <div class="bind-failure-tips" v-show="isAlreadyBind">
            <p class="binding-device-title ml-14 m4-14">Tips</p>
            <p class="mt-28 ml-14 m4-14 binding-device-content">The device has been bound to another account, please
                unbind it first.
            </p>
            <div class="later" @click="dismissDialog">No, Later</div>
        </div>
    </div>

</template>

<script>
    import deviceRepository from "../repository/store/DeviceRepository"
    import bluetoothRepository from "../repository/bluetooth/BluetoothRepository"
    import log from "../repository/log/log"

    export default {
        name   : "BindDeviceDialog",
        props  : {
            isBind    : false,
            macAddress: ''
        },
        data() {
            return {
                isAlreadyBind: this.isBind
            }
        },
        methods: {
            bindDevice() {
                deviceRepository.bindDevice(this.macAddress)
                    .then(data => {
                        return bluetoothRepository.afterConnectHandle(this.macAddress)
                    })
                    .then(data => {
                        log("绑定并且连接成功")
                    })
                    .catch(err => {
                        this.isAlreadyBind = true
                    })
            },
            dismissDialog() {
                this.$emit("onCloseBindDialog")
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bind-device-dialog-container {
        display: flex;
        flex-direction: column;
        background-color: #FCF5F5;
        color: #000000;

        .bind-device-tips {

            .button-handle-container {
                text-align: center;
                display: flex;
                flex-direction: row;

                .later {
                    flex: 1;
                    padding: 11px;
                    background-color: #222222;
                    color: #FCF5F5;
                }

                .bind-device-button {
                    flex: 1;
                    padding: 11px;
                    background-color: #F6F6F6;
                    color: #111111;
                }
            }
        }

        .bind-failure-tips {

            .tips-text {
                background-color: #F6F6F6;
                color: #111111;
            }

            .later {
                text-align: center;
                flex: 1;
                padding: 11px;
                background-color: #222222;
            }
        }
    }

    .binding-device-title {
        color: #ed6a0c;
    }

    .binding-device-content {
        font-size: 12px;
        color: #000000;
    }

    .binding-device-tips {
        font-size: 12px;
        color: #999999;
    }

</style>