<template>
    <div class="modify-device-name-container">
        <p class="rename-title">Rename Device</p>
        <div class="user-account-input">
            <van-field clsas="input-text-color"
                       placeholder="Device name"
                       size="20"
                       v-model="waitModifyName"
                       clearable/>
        </div>


        <div class="mt-18 button-handle-container">
            <div class="later" @click="dismissDialog">Cancel</div>
            <div class="bind-device-button" @click="submit">Submit</div>
        </div>
    </div>
</template>

<script>
    export default {
        name   : "ModifyDeviceName",
        props  : {
            deviceModel: {
                type   : Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                waitModifyName: this.deviceModel.deviceName
            }
        },
        methods: {
            dismissDialog() {
                this.$emit("onCloseBindDialog")
            },
            submit() {
                this.$emit("onSubmitData", this.deviceModel, this.waitModifyName)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .rename-title {
        color: #000000;
        text-align: center;
    }

    .modify-device-name-input {
        border-radius: 16px;
        margin-left: 16px;
        margin-right: 16px;
        border: 1px solid #999999;

        &:focus {
            border-radius: 16px;
            border: 1px solid #ED6C0F;
        }

        .van-cell {
            background-color: transparent;
            color: #000000 !important;
        }
    }

    .modify-device-name-container {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;

        .button-handle-container {
            text-align: center;
            display: flex;
            flex-direction: row;

            .later {
                flex: 1;
                padding: 11px;
                background-color: #FCF5F5;
                color: #000000;
            }

            .bind-device-button {
                flex: 1;
                padding: 11px;
                background-color: #FDC36B;
                color: #111111;
            }
        }
    }
</style>