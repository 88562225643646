import log from "../log/log";
import store from "../../vuex/AppStore"
import StoreType, {
    RECEIVED_ENTER_DIY_TEMPERATURE_CURVE,
    RECEIVED_EXIT_DIY_TEMPERATURE_CURVE
} from "../../vuex/StoreType"
import router from "../../router/routers"
import apiService from "../api/ApiService"
import deviceRepository from "../store/DeviceRepository"
//设备写服务与Characteristic
const DEVICE_READ_SERVICE_UUID            = "0000180a-0000-1000-8000-00805f9b34fb"        //读取服务
const VERSIONS_CHARACTERISTIC_UUID        = "00002a29-0000-1000-8000-00805f9b34fb"        //设备版本信息 Characteristic
const CIGATETTE_ID_CHARACTERISTIC_UUID    = "00002a2a-0000-1000-8000-00805f9b34fb"        //读取烟弹id Characteristic
const SMOKE_DATA_CHARACTERISTIC_UUID      = "00002a39-0000-1000-8000-00805f9b34fb"        //烟弹总口数 Characteristic
const SYNC_SMOKE_DATA_CHARACTERISTIC_UUID = "00002a40-0000-1000-8000-00805f9b34fb"        //同步每天吸烟口数Characteristic
//设备读服务与Characteristic
const DEVICE_WRITE_SERVER_UUID             = "0000180d-0000-1000-8000-00805f9b34fb"     //可读可写服务
const CIGATETTE_INFO_CHARACTERISTIC_UUID   = "00002a38-0000-1000-8000-00805f9b34fb"     //只读读烟弹信息
const SYNC_TIME_CHARACTERISTIC_UUID        = "00002a42-0000-1000-8000-00805f9b34fb"     //同步时间 Characteristic
const TEMPERATURE_MODE_CHARACTERISTIC_UUID = "00002a2c-0000-1000-8000-00805f9b34fb"           //模式切换
const PREHEAT_CHARACTERISTIC_UUID          = "00002a2d-0000-1000-8000-00805f9b34fb"           //预热温度
const NOTIFY_CHARACTERISTIC_UUID           = "00002a37-0000-1000-8000-00805f9b34fb"     //Notify(监听)
//电池服务
const BATTERY_SERVICE_UUID        = "0000180f-0000-1000-8000-00805f9b34fb"     //电池服务
const BATTERY_CHARACTERISTIC_UUID = "00002a19-0000-1000-8000-00805f9b34fb"     //电池   Characteristic

//过期解锁时间
const EXPIRE_TIME_CHARACTERISTIC_UUID =
          "00002a2e-0000-1000-8000-00805f9b34fb"                   //锁定和解锁到期时间

//预热类型
export const PREHEAT_TYPE_ENTER        = 1
export const PREHEAT_TYPE_ENTER_START  = 2
export const PREHEAT_TYPE_STOP         = 3
export const PREHEAT_TYPE_STOP_NO_EXIT = 4

//预热状态
const ENTER_PREHEAT = 1
const EXIT_PREHEAT  = 2
const START_PREHEAT = 3
const END_PREHEAT   = 4

export const PREHEAT_STATUS = {
    ENTER_PREHEAT,
    EXIT_PREHEAT,
    START_PREHEAT,
    END_PREHEAT
}

//烟弹类型
export const INGREDIRENT_TYPE_NICOTINE   = 1
export const INGREDIRENT_TYPE_THC        = 2
export const INGREDIRENT_TYPE_CBD        = 3
export const INGREDIRENT_TYPE_WAX        = 4
export const INGREDIRENT_TYPE_SUPPLEMENT = 5
export const INGREDIRENT_TYPE_DELTA_8    = 6

//设备类型
export const DEVICE_TYPE_MODEL_S = 48185
export const DEVICE_TYPE_MODEL_X = 48186
export const DEVICE_TYPE_MODEL_E = 48187

//品牌类型
const BRAND_UNICODE = 2
const BRAND_DIME    = 3
const BRAND_ANGEL   = 4

//成分信息
class IngredientInformation {
    constructor(dataView) {
        this._name    = dataView.getUint8()     //成分编码
        this._capital = dataView.getUint16(1)   //成分大小
    }

    static createInfo(arrayLike, size) {
        const info = []
        for (let i = 0; i < size; i++) {
            info.push(new IngredientInformation(new DataView(arrayLike, i * 3, 3)))
        }
        return info
    }

    toString() {
        return `\n \t\tname = ${this._name},capital = ${this._capital},`
    }
}

// 预热信息
class Preheat {
    constructor(data) {
        this.temperature = data.getInt16(0) //预热温度
        this.time        = data.getInt8(2) //预热时间
    }

    toString() {
        return `\n\t\ttemperature = ${this.temperature},time = ${this.time},`
    }
}

// 溶剂信息
class Solvent {
    constructor(dataView) {
        this._name    = dataView.getUint8(0)   //溶剂名称，编码
        this._capital = dataView.getUint8(1)  //溶剂含量 单位 毫克
    }

    static createSolvent(arrayLike, size) {
        let solvent = []
        for (let i = 0; i < size; i++) {
            solvent.push(new Solvent(new DataView(arrayLike, i * 2, 2)))
        }
        return solvent
    }

    toString() {
        return `\n\t\tname = ${this._name}, _capital = ${this._capital},`
    }
}

//

// 烟弹信息
class CartridgeInfo {
    workTempurature = []

    //读取回来的是dateView，封装了读取的字节数组
    constructor(dataView) {
        this.ingredirentType     = dataView.getUint8(0)                                       //烟弹类型 1个字节     1 尼古丁  2 thc   3 cdb   4 wax 5 医疗 6 delta-8
        this.brandName           = dataView.getUint8(1)                                       //烟弹品牌 1个字节 // 2 Uni-Core 3 Dime 4 Angel
        this.ingredirentCapital  = dataView.getUint8(2)                                       //烟弹容量 1个字节
        this.ingredirentFlavor   = dataView.getUint16(3)                                       //烟弹口味 1个字节
        this.ingredirentOrigin   = dataView.buffer.slice(5, 8)                                 //烟弹产地 3个字节
        this.ingredirentMaxCount = dataView.getUint16(8)                                       //烟弹最大秒数 2个字节
        this.content             = dataView.getUint16(10)                                       //含量 2个字节
        this.info10              = IngredientInformation                                        //10个成分信息
            .createInfo(dataView.buffer.slice(12, 3 * 10 + 12), 10)
        this.batchNum            = dataView.getUint32(42)                                       //批次号
        this.viscosty            = dataView.getUint8(46)                                       //浓稠度
        this.preheat             = new Preheat(new DataView(dataView.buffer.slice(47, 50)))                  //预热温度
        this.solvent             = Solvent.createSolvent(dataView.buffer.slice(50, 2 * 3 + 50))//溶剂3种
        this.coilType            = dataView.getUint8(56)                                       //雾化丝类型 编码
        this.workMode            = dataView.getUint8(57)                                       //工作类型
        this.retimer             = dataView.getUint16(70)                                      //工作温度6个长度
        this.specFunction        = dataView.getUint16(72)
        this.power               = dataView.getUint16(56)
        this.r                   = dataView.getUint16(76)
        this.basic               = dataView.getUint8(79)
        // this._reserve = dataView.getUint8(56)
        for (let i = 0; i < 6; i++) {
            this.workTempurature.push(dataView.getUint16(58 + 2 * i))
        }
    }

    toString() {
        return `ingredirentType = ${this.ingredirentType}\n
                brandName=${this.brandName}\n
                ingredirentCapital=${this.ingredirentCapital}\n
                ingredirentFlavor=${this.ingredirentFlavor}\n
                ingredirentOrigin=${this.ingredirentOrigin}\n
                ingredirentMaxCount=${this.ingredirentMaxCount}\n
                content=${this.content}\n
                info10=${this.info10}\n
                viscosty=${this.viscosty}\n
                solvent=${this.solvent}\n
                coilType=${this.coilType}\n
                workMode=${this.workMode}\n
                retimer=${this.retimer}\n
                specFunction=${this.specFunction}\n
                power=${this.power}\n
                r=${this.r}\n
                basic=${this.basic}\n`
    }
}

class SmokeData {
    constructor(dataView) {
        this.podId              = Buffer.from(dataView.buffer.slice(0, 8)).toString('hex').toLocaleUpperCase()
        this.year               = dataView.getUint8(8) + 2000
        this.month              = dataView.getUint8(9)
        this.day                = dataView.getUint8(10)
        this.t1                 = dataView.getUint8(11)
        this.t2                 = dataView.getUint8(12)
        this.t3                 = dataView.getUint8(13)
        this.t4                 = dataView.getUint8(14)
        this.t5                 = dataView.getUint8(15)
        this.t6                 = dataView.getUint8(16)
        this.smokeCount         = dataView.getUint16(17)
        this.totalSmokeTime     = dataView.getUint16(19)
        this.todaySmokeDuration = dataView.getUint16(21)
        this.temperature        = dataView.getUint16(23)
        this.isInsert           = dataView.getUint8(25)
        this.isLock             = dataView.getUint16(26)
        this.resever            = dataView.buffer.slice(27, 32)
    }

    toString() {
        log(`podId=${this.podId},year=${this.year},month=${this.month},day=${this.day},t1=${this.t1},t2=${this.t2},
        t3=${this.t3},t4=${this.t4},t5=${this.t5},t6=${this.t6},smokeCount=${this.smokeCount},totalSmokeTime=${this.totalSmokeTime},`)
    }
}


//wax预热
class WaxPreheat {
    constructor(dataView) {
        this.preheatTemperature = dataView.getUint16(0, true)
        this.preheatSecond      = dataView.getUint16(2, true)
    }

    static createPreheat(preheatTemperature, preheatSecond) {
        this.preheatTemperature = preheatTemperature
        this.preheatSecond      = preheatSecond
    }


    toString() {
        return `\n\t\tpreheatTemperature = ${this.preheatTemperature}, preheatSecond = ${this.preheatSecond},`
    }
}

/**
 * DIY温度
 */
class TemperatureMode {
    name              = ""
    secondTemperature = []

    constructor(dataView) {
        if (dataView) {
            this.secondTemperature = []
            let index              = 0
            for (let i = 20; i < 62; i++) {
                if (dataView.getUint8(i) === 0) {
                    index = i
                    break
                }
            }
            this.name = Buffer.from(dataView.buffer.slice(20, index)).toString('utf-8')
            for (let i = 0; i < 10; i++) {
                this.secondTemperature.push({x: (i + 1), y: dataView.getUint16(2 * i, true)})
            }

            log(`温度模式值 name=${this.name}`)
            for (let i = 0; i < this.secondTemperature.length; i++) {
                log(`温度模式值 temperature ${i} = ${this.secondTemperature[i].y}`)
            }
        }
    }

    static createTemperatureMode(name, secondTemperature) {
        const temperatureMode             = new TemperatureMode(null)
        temperatureMode.name              = name
        temperatureMode.secondTemperature = []
        for (let i = 0; i < 6; i++) {
            temperatureMode.secondTemperature.push({x: (i + 1), y: secondTemperature[i]})
        }
        return temperatureMode
    }
}

class BluetoothRepository {
    _cacheServices        = {}
    _cacheCharacteristics = {}
    _decoder              = new TextDecoder()
    _server               = null
    device                = null
    _isFire               = false
    _timeoutFireId        = -1

    constructor() {
        //监听设备的连接状态
        if (navigator.bluetooth && navigator.bluetooth.addEventListener) {
            navigator.bluetooth.addEventListener("availabilitychanged", e => {
                log("availabilitychanged = " + e)
                if (e) {
                    router.push("Pair")
                }
            })
        }

    }

    async startPair(callback) {
        this.disconnect()
        let device  = await navigator.bluetooth.requestDevice({
            filters         : [
                {namePrefix: 'UC'},
                {namePrefix: 'EG'},
                {namePrefix: 'WX'},
            ],
            optionalServices: [DEVICE_READ_SERVICE_UUID, DEVICE_WRITE_SERVER_UUID, BATTERY_SERVICE_UUID,]
        });
        this.device = device
        //监听设备的连接状态
        device.addEventListener("gattserverdisconnected", e => {
            log("设备连接断开")
            //提交状态到vuex
            store.commit(StoreType.COMMIT_TYPE.COMMIT_CONNECT_STATE, StoreType.connectState.CONNECT_STATE_DISCONNECTED)
        })

        this._server = await device.gatt.connect()


        log(`device name = ${device.name}`)

        if (device.name.length !== 18) {
            this._server.disconnect()
            return
        }

        const deviceName = device.name.substring(4, 16)
        let macAddress   = ''
        for (let i = 0; i < 6; i++) {
            macAddress += deviceName.substring(i * 2, (i + 1) * 2)
            if (i !== 5) {
                macAddress += ':'
            }
        }
        const unicoreReadService           = await this._server.getPrimaryService(DEVICE_READ_SERVICE_UUID)
        const unicoreWriteService          = await this._server.getPrimaryService(DEVICE_WRITE_SERVER_UUID)
        const batteryService               = await this._server.getPrimaryService(BATTERY_SERVICE_UUID)
        //缓存好service
        this._cacheServices.readService    = unicoreReadService
        this._cacheServices.writeService   = unicoreWriteService
        this._cacheServices.batteryService = batteryService
        this.device.macAddress             = macAddress

        const deviceNameFromServer = await deviceRepository.getDeviceNameFromServer(macAddress, device.name)
        if (deviceNameFromServer.includes("(Bound)")) {
            this.afterConnectHandle(macAddress)
        } else {
            //没有绑定，就提示他要绑定
            callback(macAddress, false) //false是表示没有绑定
        }

    }

    async afterConnectHandle(macAddress) {
        //打开notifacition
        await this.enableNotification()
        //写入同步时间
        let syncTime = ''
        try {
            syncTime = await this.syncTime(macAddress, this.device.name, `GMT${new Date().getTimezoneOffset() / -60}`)
        } catch (e) {
            syncTime = await this.syncTimeOld()
        }
        const version = await this.readVersion()
        //组成对象，提交到vuex
        store.commit(StoreType.COMMIT_TYPE.COMMIT_DEVICE_INFO, {
            version,
            syncTime,
            macAddress
        })
        await this.fetchDeviceData()

        //提交状态到vuex
        store.commit(StoreType.COMMIT_TYPE.COMMIT_CONNECT_STATE, StoreType.connectState.CONNECT_STATE_CONNECTED)
    }

    async fetchDeviceData() {
        //读取数据
        const battery       = await this.readBattery()
        const cartridgeInfo = await this.readCartridgeInfo()
        const podId         = await this.readPodId()
        if (cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_X || cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_E) {
            //如果是这两个设备，就要读取DIY温度和预热信息
            const waxPreheat           = await this.readPreheat()
            const preheatTemperateMode = await this.readTemperatureMode()
            store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_INFO, waxPreheat)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE, preheatTemperateMode)

            try {
                const diyTemperature = await deviceRepository.diyTemperatureMode(podId)
                store.commit(StoreType.COMMIT_TYPE.COMMIT_DIY_TEMPERATURE_MODE, diyTemperature)
            } catch (e) {
            }
        } else {
            //model-s设备 需要转换一下
            const waxPreheat           = WaxPreheat.createPreheat(cartridgeInfo.preheat.temperature, cartridgeInfo.preheat.time)
            const preheatTemperateMode = TemperatureMode.createTemperatureMode("", cartridgeInfo.workTempurature)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_INFO, waxPreheat)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE, preheatTemperateMode)
        }
        //组成对象，提交到vuex
        store.commit(StoreType.COMMIT_TYPE.COMMIT_DEVICE_INFO, {
            ...store.state.deviceInfo,
            ...{
                podId,
                battery,
                cartridgeInfo

            }
        })

        return cartridgeInfo
    }

    //打开notification
    async enableNotification() {
        const notifyCharacteristic = await this._cacheServices.writeService.getCharacteristic(NOTIFY_CHARACTERISTIC_UUID)
        await notifyCharacteristic.startNotifications()
        notifyCharacteristic.addEventListener("characteristicvaluechanged", event => {
            const characteristic = event.target;
            const data           = characteristic.value;
            const data1          = data.getUint8(0)
            const data2          = data.getUint8(1)
            const dataArray      = [data1, data2]
            const buffer         = Buffer.from(dataArray)
            const bufferStr      = buffer.toString('hex')
            log(`接收到notify的数据 data1 = ${bufferStr}`)
            //电量
            if (data1 === 0xba) {
                store.commit(StoreType.COMMIT_TYPE.COMMIT_BATTERY_CHANGED, data2)
                return
            }
            switch (bufferStr) {
                case '00c5':           //同步时间
                    log("同步时间")
                    break
                case '00c6':           //开始点火
                    log("开始点火")
                    if (this._timeoutFireId !== -1) clearTimeout(this._timeoutFireId)
                    this._isFire = true
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_IS_FIRE, true)
                    break
                case '00c7':           //停止点火
                    log("停止点火")
                    if (this._timeoutFireId !== -1) clearTimeout(this._timeoutFireId)
                    this._isFire = false
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_IS_FIRE, false)
                    this.readSmokeData()
                    break
                case '00c8':           //烟弹插入
                    log("烟弹插入")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INSERT_CHANGED, true)
                    break
                case '00c9':           //烟弹拔出
                    log("烟弹拔出")

                    store.commit(StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INSERT_CHANGED, false)
                    break
                case '00d6':           //同步烟弹口数应答
                    log("同步烟弹口数应答")
                    this.readSmokeData()
                    break
                case 'aaca':           //开始预热
                    log("开始预热")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, START_PREHEAT)
                    break
                case 'aacb':           //停止预热
                    log("停止预热")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, END_PREHEAT)
                    break
                case 'aacc':           //设备预热参数发生改变
                    log("设备预热参数发生改变")
                    this.readPreheat()
                    break
                case 'aacd':           //进入预热模式
                    log("进入预热模式")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, ENTER_PREHEAT)
                    break
                case 'aace':           //退出预热模式
                    log("退出预热模式")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, EXIT_PREHEAT)
                    break
                case 'aabd':           //进入diy温度模式
                    log("进入diy温度模式")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_SWITCH_TEMPERATURE_MODE, RECEIVED_ENTER_DIY_TEMPERATURE_CURVE)
                    break
                case 'aabe':           //退出diy温度模式
                    log("退出diy温度模式")
                    store.commit(StoreType.COMMIT_TYPE.COMMIT_SWITCH_TEMPERATURE_MODE, RECEIVED_EXIT_DIY_TEMPERATURE_CURVE)
                    break
                default:  //这里接收的是阻值数据，启动延迟检查
                    if (this._isFire) {
                        this._timeoutFire()
                    }
                    break

            }

        })
        this._cacheCharacteristics.notify = notifyCharacteristic
    }

    /**
     * 取消之前的先，然后重新启动timeout
     * @private
     */
    _timeoutFire() {
        log(`待取消的timeoutId ${this._timeoutFireId}`)
        if (this._timeoutFireId !== -1) clearTimeout(this._timeoutFireId)

        this._timeoutFireId = setTimeout(() => {
            log(`准备执行timeout的内容`)
            this._isFire = false
            store.commit(StoreType.COMMIT_TYPE.COMMIT_IS_FIRE, false)
        }, 500) //500毫秒后收不到

        log(`准备执行的timeoutId ${this._timeoutFireId}`)
    }

    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len           = binary_string.length;
        var bytes         = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }

    //写入同步时间
    async syncTime(macAddress, deviceType, timeZone) {

        let synchronizeTime = await deviceRepository.synchronizeTime(macAddress, deviceType, timeZone)
        let currentTime     = this._base64ToArrayBuffer(synchronizeTime)

        // const date        = new Date()
        // const currentTime = new DataView(Buffer.alloc(7).buffer)
        // currentTime.setInt16(0, date.getFullYear())
        // currentTime.setInt8(2, date.getMonth() + 1)
        // currentTime.setInt8(3, date.getDate())
        // currentTime.setInt8(4, date.getHours())
        // currentTime.setInt8(5, date.getMinutes())
        // currentTime.setInt8(6, date.getSeconds())

        const syncTimeCharacteristic = await this._cacheServices.readService.getCharacteristic(EXPIRE_TIME_CHARACTERISTIC_UUID)
        await syncTimeCharacteristic.writeValue(currentTime)
        this._cacheCharacteristics.syncTimeCharacteristic = syncTimeCharacteristic
        return ""
    }

    //写入同步时间
    async syncTimeOld(macAddress, deviceType, timeZone) {
        const date        = new Date()
        const currentTime = new DataView(Buffer.alloc(7).buffer)
        currentTime.setInt16(0, date.getFullYear())
        currentTime.setInt8(2, date.getMonth() + 1)
        currentTime.setInt8(3, date.getDate())
        currentTime.setInt8(4, date.getHours())
        currentTime.setInt8(5, date.getMinutes())
        currentTime.setInt8(6, date.getSeconds())
        const syncTimeCharacteristic = await this._cacheServices.writeService.getCharacteristic(SYNC_TIME_CHARACTERISTIC_UUID)
        await syncTimeCharacteristic.writeValue(currentTime)
        this._cacheCharacteristics.syncTimeCharacteristic = syncTimeCharacteristic
        return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }

    //读取电量
    async readBattery() {
        const batteryCharacteristics = await this._cacheServices.batteryService.getCharacteristic(BATTERY_CHARACTERISTIC_UUID)
        const batteryDataView        = await batteryCharacteristics.readValue()
        const batteryValue           = batteryDataView.getUint8()
        log("电池电量" + batteryValue)
        this._cacheCharacteristics.batteryCharacteristics = batteryCharacteristics
        return batteryValue
    }

    //获取版本信息
    async readVersion() {
        const versionCharacteristics = await this._cacheServices.readService.getCharacteristic(VERSIONS_CHARACTERISTIC_UUID)
        const versionDataView        = await versionCharacteristics.readValue()
        const versionInfo            = this._decoder.decode(versionDataView.buffer)
        log("版本信息" + versionInfo)
        this._cacheCharacteristics.versionCharacteristics = versionCharacteristics
        return versionInfo
    }

    //获取烟弹信息
    async readCartridgeInfo() {
        const cartridgeInfoCharacteristics = await this._cacheServices.writeService.getCharacteristic(CIGATETTE_INFO_CHARACTERISTIC_UUID)
        const cartridgeInfoDataView        = await cartridgeInfoCharacteristics.readValue()
        const cartridgeInfo                = new CartridgeInfo(cartridgeInfoDataView)
        log("烟弹信息" + cartridgeInfo)
        this._cacheCharacteristics.cartridgeInfoCharacteristics = cartridgeInfoCharacteristics
        return cartridgeInfo
    }

    async updatePreheatAndDiyTemperature(cartridgeInfo) {
        if (cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_S) {
            const waxPreheat           = WaxPreheat.createPreheat(cartridgeInfo.preheat.temperature, cartridgeInfo.preheat.time)
            const preheatTemperateMode = TemperatureMode.createTemperatureMode("", cartridgeInfo.workTempurature)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_INFO, waxPreheat)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE, preheatTemperateMode)
        } else if (cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_E || cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_X) {
            //如果是这两个设备，就要读取DIY温度和预热信息
            const waxPreheat           = await this.readPreheat()
            const preheatTemperateMode = await this.readTemperatureMode()
            store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_INFO, waxPreheat)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE, preheatTemperateMode)

            try {
                const diyTemperature = await deviceRepository.diyTemperatureMode(podId)
                store.commit(StoreType.COMMIT_TYPE.COMMIT_DIY_TEMPERATURE_MODE, diyTemperature)
            } catch (e) {
            }
        }
    }

    //读取烟弹id信息
    async readPodId() {
        const podIdCharacteristics = await this._cacheServices.readService.getCharacteristic(CIGATETTE_ID_CHARACTERISTIC_UUID)
        const podIdDataView        = await podIdCharacteristics.readValue()
        const size                 = podIdDataView.byteLength
        const podIdArray           = podIdDataView.buffer
        if (size === 11) {
            let podId = Buffer.from(podIdArray.slice(0, 8)).toString('hex').toLocaleUpperCase()
            console.log(`podId = ${podId}`)
            if (podId === '0000000000000000') {
                this.disconnect()
                return
            }
            this._cacheCharacteristics.podIdCharacteristics = podIdCharacteristics

            //提交状态到vuex
            // store.commit(StoreType.COMMIT_TYPE.COMMIT_POD_ID, podId)
            return podId
        }
    }

    //读取吸烟口数，同步信息
    async readSmokeData() {
        const smokeDataCharacteristics = await this._cacheServices.writeService.getCharacteristic(SMOKE_DATA_CHARACTERISTIC_UUID)
        const smokeDataDataView        = await smokeDataCharacteristics.readValue()
        const smokeData                = new SmokeData(smokeDataDataView)
        //烟弹id不对，断开连接
        if (smokeData.podId === '0000000000000000') {
            this.disconnect()
            return
        }
        log(smokeData)
        store.commit(StoreType.COMMIT_TYPE.COMMIT_SYNC_SMOKE_DATA, smokeData)
        return smokeData
    }

    //请求同步吸烟信息
    async syncSmokeData() {
        const syncSmokeDataCharacteristics = await this._cacheServices.writeService.getCharacteristic(SYNC_SMOKE_DATA_CHARACTERISTIC_UUID)
        await syncSmokeDataCharacteristics.writeValue(new Uint8Array([1, 2, 3, 4]))
    }

    //读取温度模式
    async readTemperatureMode() {
        const readTemperatureModeCharacteristic = await this._cacheServices.readService.getCharacteristic(TEMPERATURE_MODE_CHARACTERISTIC_UUID)
        const readTemperatureDataDataView       = await readTemperatureModeCharacteristic.readValue()
        const temperatureMode                   = new TemperatureMode(readTemperatureDataDataView)
        store.commit(StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE, temperatureMode)
        return temperatureMode
    }

    //写入温度模式
    async writeTemperatureMode(temperatureMode) {
        const name              = temperatureMode.name
        const secondTemperature = temperatureMode.secondTemperature

        const sendTemperatureArrayBuffer = new ArrayBuffer(64)
        const sendTemperatureDataView    = new DataView(sendTemperatureArrayBuffer)

        if (secondTemperature.length > 5) {
            for (let i = 0; i < 10; i++) {
                if (i > 5) {
                    sendTemperatureDataView.setUint16(i * 2, secondTemperature[5].y, true)
                } else {
                    sendTemperatureDataView.setUint16(i * 2, secondTemperature[i].y, true)
                }
            }
        }

        const nameBuffer = Buffer.from(name)
        const nameLength = nameBuffer.byteLength
        const maxLength  = nameLength > 44 ? 44 : nameLength


        for (let i = 0; i < maxLength; i++) {
            sendTemperatureDataView.setUint8(20 + i, nameBuffer[i])
        }


        const sendDataBuffer = new ArrayBuffer(80)
        let sendDataDataView = new DataView(sendDataBuffer)
        for (let i = 0; i < 4; i++) {
            const buffer         = sendTemperatureDataView.buffer.slice(16 * i, 16 * (i + 1))
            const bufferDataView = new DataView(buffer)
            sendDataDataView.setUint8(20 * i, 0xAA)
            sendDataDataView.setUint8(20 * i + 1, i + 1)
            const byteLength = buffer.byteLength
            for (let j = 0; j < byteLength; j++) {
                sendDataDataView.setUint8(20 * i + 2 + j, bufferDataView.getUint8(j))
            }
            let checkSum = 0
            checkSum += 0xAA
            checkSum += (i + 1)
            for (let j = 0; j < buffer; j++) {
                checkSum += buffer.getUint8()
            }
            sendDataDataView.setUint16(18 * (i + 1) + 2 * i, checkSum, true)
        }

        const writeTemperatureDataDataView = await this._cacheServices.readService.getCharacteristic(TEMPERATURE_MODE_CHARACTERISTIC_UUID)
        try {
            for (let i = 0; i < 4; i++) {
                await writeTemperatureDataDataView.writeValue(sendDataDataView.buffer.slice(i * 20, (i + 1) * 20))
            }
            store.commit(StoreType.COMMIT_TYPE.COMMIT_TEMPERATE_MODE, temperatureMode)
        } catch (e) {

        }
    }

    //读取预热
    async readPreheat() {
        const readPreheatCharacteristic = await this._cacheServices.readService
            .getCharacteristic(PREHEAT_CHARACTERISTIC_UUID)
        const readPreheatDataView       = await readPreheatCharacteristic.readValue()
        if (readPreheatDataView.byteLength === 6) {
            const waxPreheat = new WaxPreheat(readPreheatDataView)
            store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_INFO, waxPreheat)
            return waxPreheat
        }
    }

    //设置预热
    async writePreheat(type, preheatTemperature, preheatSecond) {
        const preheatParamsBuffer = new ArrayBuffer(6)
        const preheatDataView     = new DataView(preheatParamsBuffer)
        preheatDataView.setUint16(0, preheatTemperature, true)
        preheatDataView.setUint16(2, preheatSecond, true)

        let checkSum = 0
        for (let i = 0; i < 4; i++) {
            checkSum += preheatDataView.getUint8(i)
        }
        preheatDataView.setUint16(4, checkSum, true)


        const sendBuffer   = new ArrayBuffer(10)
        const sendDataView = new DataView(sendBuffer)

        sendDataView.setUint8(0, 0xAA)
        switch (type) {
            case PREHEAT_TYPE_ENTER:
                sendDataView.setUint8(1, 0x01)
                break;
            case PREHEAT_TYPE_ENTER_START:
                sendDataView.setUint8(1, 0x02)
                break;
            case PREHEAT_TYPE_STOP:
                sendDataView.setUint8(1, 0x03)
                break;
            case PREHEAT_TYPE_STOP_NO_EXIT:
                sendDataView.setUint8(1, 0x04)
                break;
        }
        sendDataView.setUint16(2, preheatTemperature, true)
        sendDataView.setUint16(4, preheatSecond, true)
        sendDataView.setUint16(6, checkSum, true)


        checkSum = 0
        for (let i = 0; i < 8; i++) {
            checkSum += sendDataView.getUint8(i)
        }

        sendDataView.setUint16(8, checkSum, true)

        const writePreheatCharacteristic = await this._cacheServices.readService
            .getCharacteristic(PREHEAT_CHARACTERISTIC_UUID)
        await writePreheatCharacteristic.writeValue(sendDataView.buffer)
    }


    disconnect() {
        if (this._server) {
            this._server.disconnect()
            this.device = null
        }
    }

}

const
    bluetoothRepository = new BluetoothRepository();
export default bluetoothRepository