<template>
    <div id="login">
        <van-form>
            <div class="img-box">
                <div :class="logoIcon"></div>
            </div>

            <div class="login-input">
                <div class="user-account-input">
                    <van-field class="input-text-color"
                               v-model="loginData.username"
                               placeholder="E-mail"
                               size="32"
                               clearable/>
                </div>

                <div class="mt-13 user-account-input">
                    <van-field clsas="input-text-color"
                               type="password"
                               v-model="loginData.password"
                               placeholder="Password"
                               size="20"
                               clearable/>
                </div>

                <div class="mt-10 ml-48">
                    <span class="user-account-text" @click="routeForget">Forgot password?</span>
                </div>


                <div class="ml-30 mr-30">
                    <van-button @click="login" class="mt-16 center-btn user-account-btn" block round type="primary">登录
                    </van-button>
                </div>

                <div class="mt-10 ml-48">
                    <span class="user-account-text">No account?</span><span class="sign-up" @click="routeRegsiter">Sign up</span>
                </div>
            </div>


        </van-form>
    </div>
</template>

<script>
    import log from "../repository/log/log"
    import rule from "../rule/rule"
    import userRepository from "../repository/store/UserRepository"

    export default {
        name   : "Login",
        data() {
            return {
                loginData: {
                    username: '',
                    password: '',
                }
            }
        },
        methods: {
            routeForget() {
                this.$router.replace({name: 'Forget'})
            },
            routeRegsiter() {
                this.$router.replace({name: 'Register'})
            },
            login() {
                const {username, password} = this.loginData

                if (!rule.email.validator(username)) {
                    return
                }

                if (!rule.password.validator(password)) {
                    return
                }


                const _this = this
                userRepository.login({email: username, password})
                    .then(data => {
                        _this.$router.push({name: "Pair"})
                    })
                    .catch(err => {
                        log(err)
                    })
            }
        },
        computed: {
            logoIcon() {
                if (location.hostname === 'dime.unicoreus.com') {
                    return 'logo-dime'
                } else if (location.hostname === 'app.unicoreus.com') {
                    return 'logo-unicore'
                }else {
                    return 'logo-unicore'
                }
            },
        },
        beforeMount() {
            if (userRepository.isLogin()) {
                this.$router.push({name: "Home"})
            }
        },
        mounted() {

        }
    }
</script>