import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/routers'
import store from './vuex/AppStore'

import {
    Image, Field, Button, Toast, Form, Col, Row, Popup,
    Uploader, RadioGroup, Radio, Checkbox, Step, Steps,
    PasswordInput, NumberKeyboard, Tab, Tabs
} from 'vant'

Vue.config.productionTip = false
Vue.use(VueRouter)
//引入Vant
Vue.use(Image)
Vue.use(Field)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Uploader)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Col)
Vue.use(Row)
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Tab);
Vue.use(Tabs);


new Vue({
    render  : h => h(App),
    router,
    store
}).$mount('#app')

