import axios from 'axios'
import log from '../log/log'
import router from '../../router/routers'
import {clearToast, failToast, loading} from '../../util/util'
import storeRepository from "../store/StoreRepository"
import userRepository from "../store/UserRepository"

const CODE_SUCCESS = 200;
const CODE_EXPIRED = 401;

const TOKEN      = "front-token"
const ASCRIPTION = "ASCRIPTION"
const baseURL    = process.env.NODE_ENV === 'development' ? null : 'https://app.unicoreus.com/supplement/'
const api        = axios.create({
        baseURL,
        timeout: 10000
    }
);

api.interceptors.request.use(function (config) {
    if (config.isShowLoading) {
        // log(config)
        loading('Loading...')
    }
    if (storeRepository.token) {
        config.headers[TOKEN] = storeRepository.token
    }
    if (location.hostname === 'app.unicoreus.com') {
        config.headers[ASCRIPTION] = 4
    } else if (location.hostname === 'dime.unicoreus.com') {
        config.headers[ASCRIPTION] = 5
    } else if (location.hostname === 'supplement.unicoreus.com') {
        config.headers[ASCRIPTION] = 3
    } else {
        config.headers[ASCRIPTION] = 4
    }
    return config;
}, function (error) {

    return Promise.reject(error);
});

// Add a response interceptor
api.interceptors.response.use(function (response) {
    const {code} = response.data;
    if (code === CODE_SUCCESS) {
        clearToast()
        return response.data.data;
    } else if (code === CODE_EXPIRED) {
        failToast(response.data.message);
        userRepository.logout()
        return Promise.reject(response.data.message);
    } else {
        failToast(response.data.message);
        return Promise.reject(response.data.message);
    }
}, function (error) {
    return Promise.reject(error);
});


class ApiService {
    async splashPage({success, fail}) {
        try {
            const response = await this._postQuery('front/user/findSystemSetup');
            log("splashPage = " + response);
            success(response)
        } catch (e) {
            log(e);
            fail(e)
        }
    }

    async login(data) {
        return await this._postQuery('front/user/doLogin', data, true)
    }

    async register(data) {
        return await this._postQuery('front/user/addUser', data, true)
    }

    async cartridgeInfo(data) {
        return await this._getBody('front/device/smokeRecord', data)
    }

    async deviceConnection(data) {
        return await this._postQuery('front/device/deviceConnection', data)
    }

    async syncSmokeData(data) {
        return await this._postBody('front/device/deviceSynchronization', data)
    }

    async operatingModeList(data) {
        return await this._getBody("front/device/operatingModeList", data)
    }

    async sendEmail(data) {
        data.ascription = 5
        return await this._postQuery("front/user/sendEmail", data, true)
    }


    async verifyCode(data) {
        data.ascription = 5
        return await this._getBody("front/user/verificationCode", data, true)
    }


    async resetPassword(data) {
        data.ascription = 5
        return await this._postQuery("front/user/retrievePassword", data, true)
    }

    async uploadFile(data) {
        return await api.post('front/user/uploadFile', data, {
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8'
            }
        })
    }

    async getUserInfo() {
        return await api.get('front/user/findUser')
    }

    async messageList(data) {
        return await this._getBody('front/messages/list', data)
    }

    async messageDetail(data) {
        return await this._getBody('front/messages/findById', data)
    }

    async isUnreadMessage() {
        return await api.get('front/messages/findByReadCount')
    }

    /**
     * 我的faq问答
     * @returns {Promise<void>}
     */
    async mineFaq() {
        return await api.get('front/user/commonProblemList')
    }


    /**
     * 烟弹faq
     * @param data
     * @returns {Promise<*>}
     */
    async consulationList() {

    }

    /**
     *
     * @param macAddress 设备蓝牙地址
     * @param deviceName 设备名称
     * @returns {Promise<void>}
     */
    async getDeviceNameFromServer(macAddress, deviceName) {
        return await this._getBody("front/device/findByDeviceNumber", {
            deviceNumber: macAddress,
            deviceType  : deviceName,
        })
    }

    /**
     * 绑定设备
     * @param macAddress 蓝牙地址
     * @returns {Promise<void>}
     */
    async bindDevice(macAddress) {
        return await this._postQuery("front/device/bindingDevice", {
            deviceNumber: macAddress
        })
    }

    /**
     * 获取我已经绑定的设备
     * @returns {Promise<*>}
     */
    async myDevices() {
        return await this._getBody("front/device/selectMyDevices")
    }

    /**
     * 修改已绑定设备名称
     * @param deviceId
     * @param deviceName
     * @returns {Promise<*>}
     */
    async modifyDeviceName(deviceId, deviceName) {
        return await this._postQuery("front/device/updateById", {
            id           : deviceId,
            deviceName,
            isShowLoading: true
        })
    }

    /**
     * 解绑已经绑定的设备
     * @param deviceId
     * @returns {Promise<*>}
     */
    async unbindDevice(deviceId) {
        return await this._postQuery("front/device/untieDevice", {
            id: deviceId
        })
    }

    async synchronizeTime(macAddress, deviceType, timeZone) {
        return await this._postQuery("front/device/synchronizeTime", {
            deviceNumber: macAddress,
            deviceType,
            timeZone
        })
    }

    async queryDeviceBind(macAddress, deviceType) {
        return await this._postQuery("front/device/findByDeviceNumber", {
            deviceNumber: macAddress,
            device
        })
    }

    async supplementRecord(smokeRecordNumber, pageNum = 1, pageSize = 20) {
        return await this._getBody('front/device/smokingTimeRecord', {
            smokeRecordNumber, pageNum, pageSize
        })
    }

    async modifyProfile(data) {
        return await this._postQuery('front/user/update', data, true)
    }

    async _postQuery(url, data = null, isShowLoading = false) {
        return await api.post(url, null, {params: data, isShowLoading})
    }

    async _postBody(url, data = null) {
        return await api.post(url, data)
    }

    async _getBody(url, data = null, isShowLoading = false) {
        return await api.get(url, {params: data, isShowLoading})
    }
}

const apiService = new ApiService();
export default apiService;