<template>
    <div id="home">
        <div class="home-container">
            <div class="top-container logo-min-height">
                <div class="left"></div>
                <div class="center">
                    <van-image
                            width="130px"
                            height="90px"
                            fit="contain"
                            :show-error="false"
                            :show-loading="false"
                            v-show="isInsert "
                            :src="logo"/>
                </div>
                <div class="right">
                    <div class="more" @click="mineClick"></div>
                </div>
            </div>
            <div class="bluetooth-battery-container">
                <div class="battery">
                    <van-image
                            width="9px"
                            height="18px"
                            fit="contain"
                            v-show="isInsert"
                            :src="battery"/>
                </div>
                <div class="disconnect-bluetooth" @click="disconnectDevice"></div>
            </div>


            <div class="taste-fun-container ">
                <div class="left ml-32"></div>
                <div class="center">
                    <smoke-progress
                            v-show="TASTE_TYPE.TASTE_TYPE_DOSAGE === tasteType || TASTE_TYPE.TASTE_TYPE_TEMPERATURE === tasteType"
                            ref="smokeProgress"
                            width="166px"
                            height="166px"
                            :progress="progress"
                            :doses="cartridgeInfoServer"
                            :puff-count="smokeData.smokeCount"
                            :is-insert="isInsert"
                            v-on:secondChanged="secondChanged"
                            :is-fire="isFire"/>

                    <wave-progress
                            width="166px"
                            height="166px"
                            :preheat-status="preheatStatus"
                            :preheat-second="preheatSecond"
                            v-show="TASTE_TYPE.TASTE_TYPE_PREHEAT === tasteType"/>
                </div>
                <div class="right mr-32"></div>
            </div>
            <div>
                <TasteSettingContainer
                        class="mt-10 ml-32 mr-32"
                        v-show="false"
                        v-on:tasteSettingChanged="tasteSettingChanged"
                        :taste-type="tasteType"/>
            </div>

            <PreheatContainer :preheat="preheatInfo"
                              v-on:preheatParamsChanged="preheatParamsChanged"
                              :is-start-preheat="false"
                              v-show="false"
                              class="mt-20 ml-32 mr-32"/>
            <div style="background: #EEE4E0;min-height: 4px" class="mt-16"></div>
            <FormulaContainer
                    v-show="TASTE_TYPE.TASTE_TYPE_DOSAGE === tasteType"
                    :cartridge-info-server="cartridgeInfoServer"
                    :is-insert="isInsert"
                    :second="second"/>
            <div class="mt-32" v-show="TASTE_TYPE.TASTE_TYPE_DOSAGE === tasteType && isInsert">
                <DurationRingView :smoke-data="smokeData" :cartridge-info-server="cartridgeInfoServer"/>
            </div>
            <div class="mt-16 ml-16 mr-16">
                <van-image
                        width="100%"
                        height="110px"
                        fit="contain"
                        @click="onMsdsClicked"
                        :show-error="false"
                        :show-loading="false"
                        :src="msds"/>
            </div>

            <div class="ml-32 mr-32  mt-24">
                <TemperatureMode :is-show="false"
                                 :is-fire="isFire"
                                 :show-line="false"
                                 :temperature-data="temperatureMode"
                                 v-on:showTemperatureSettingChanged="showTemperatureSettingChanged"
                                 v-show="false"/>
            </div>


            <div class="info-container">
                <div class="btn-container">
                    <button class="ingre-btn left  ml-10 mr-5" @click="ingredientInfo">Cartridge Info</button>
                    <button class="ingre-btn right mr-10 ml-5" @click="myRecord">My Records</button>
                </div>
            </div>
            <span v-show="false">{{switchTemperatureMode}}</span>
        </div>

        <van-popup v-model="isShowTemperatureModeSetting" round opened="onPopupOpened">
            <TemperatureSetting
                    v-on:onCloseTemperatureSetting="onCloseTemperatureSetting"
                    :temperature-data="temperatureMode"
                    :show-line="true"/>
        </van-popup>
    </div>
</template>

<script>
    import log from "../repository/log/log";
    import SmokeProgress from "./SmokeProgress";
    import FormulaItem from "./FormulaItem";
    import bluetoothRepository, {
        INGREDIRENT_TYPE_SUPPLEMENT,
        PREHEAT_TYPE_ENTER,
        PREHEAT_TYPE_STOP,
        PREHEAT_STATUS, DEVICE_TYPE_MODEL_S, DEVICE_TYPE_MODEL_X, DEVICE_TYPE_MODEL_E
    } from "../repository/bluetooth/BluetoothRepository";
    import deviceRepository from "../repository/store/DeviceRepository";
    import FormulaContainer from "./FormulaContainer"
    import StoreType, {
        RECEIVED_ENTER_DIY_TEMPERATURE_CURVE,
        RECEIVED_EXIT_DIY_TEMPERATURE_CURVE
    } from "../vuex/StoreType"
    import PreheatContainer from "./PreheatContainer"
    import TasteSettingContainer from "./TasteSettingContainer"
    import WaveProgress from "./WaveProgress"
    import TemperatureMode from "./TemperatureMode"
    import TemperatureSetting from "./TemperatureSetting"
    import DurationRingView from "./DurationRingView"

    export default {
        name      : "Home",
        components: {
            DurationRingView,
            TemperatureSetting,
            TemperatureMode,
            WaveProgress,
            TasteSettingContainer,
            PreheatContainer,
            FormulaContainer,
            SmokeProgress,
            FormulaItem
        },
        data() {
            return {
                TASTE_TYPE                  : StoreType.TASTE_TYPE,
                PREHEAT_STATUS              : PREHEAT_STATUS,
                cartridgeInfo               : this.$store.state.deviceInfo.cartridgeInfo,
                progress                    : {
                    current: 0,
                    end    : 360,
                },
                second                      : 0,
                preheatSecond               : 12, //给预热组件倒计时用的秒数
                tasteType                   : StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE,
                isPreheating                : false,
                batteryIcon                 : {
                    level4: require("../assets/icon_e.png"),
                    level3: require("../assets/icon_e1.png"),
                    level2: require("../assets/icon_e2.png"),
                    level1: require("../assets/icon_e3.png"),
                    level0: require("../assets/icon_e4.png"),
                },
                msds: require("../assets/guide@2x.png"),
                logoIcon                    : {
                    unicore : require("../assets/logo_unicore@3x.png"),
                    dime    : require("../assets/logo_dime@2x.png"),
                    angel   : require("../assets/logo_angel@2x.png"),
                    biscuits: require("../assets/logo_biscuits@2x.png")
                },
                isShowTemperatureModeSetting: false,
            }
        },
        methods   : {
            onMsdsClicked() {
                this.$router.push({
                    name: "Web", params: {
                        url  : 'https://supplement.unicoreus.com/precautions',
                        title: 'About Us'
                    }
                })
            },
            mineClick() {
                this.$router.push("Mine")
            },
            onCloseTemperatureSetting() {
                this.isShowTemperatureModeSetting = false
            },
            showTemperatureSettingChanged(isShow) {
                this.isShowTemperatureModeSetting = isShow
            },
            secondChanged(second) {
                this.second = parseFloat(second)
            },
            disconnectDevice() {
                bluetoothRepository.disconnect();
            },
            async syncData() {
                //写入同步数据，接着会接收到蓝牙的notify，在notify那边去读取数据
                return await bluetoothRepository.syncSmokeData()
            },
            //从服务器上获取烟弹信息
            async getCartridgeInfoFromServer() {
                const podId      = this.$store.state.deviceInfo.podId
                const batchNum   = this.$store.state.deviceInfo.cartridgeInfo.batchNum
                const macAddress = this.$store.state.deviceInfo.macAddress
                log(`home pod id = ${podId} batchNum = ${batchNum}`);

                //设备连接
                await deviceRepository.deviceConnection(batchNum, macAddress, podId)
                const cartridgeInfoServer = await deviceRepository.cartridgeInfo(`${batchNum}&${podId}`)

                this.$store.commit(StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INFO_SERVER, cartridgeInfoServer)
                return cartridgeInfoServer
            },
            //从服务器上获取烟弹信息
            async updateCartridgeInfoFromServer(podId, batchNum) {
                const macAddress = this.$store.state.deviceInfo.macAddress
                log(`home pod id = ${podId} batchNum = ${batchNum}`);

                //设备连接
                await deviceRepository.deviceConnection(batchNum, macAddress, podId)
                const cartridgeInfoServer = await deviceRepository.cartridgeInfo(`${batchNum}&${podId}`)

                this.$store.commit(StoreType.COMMIT_TYPE.COMMIT_CARTRIDGE_INFO_SERVER, cartridgeInfoServer)
                return cartridgeInfoServer
            },

            //监听vuex的烟弹信息状态变化
            ingredientInfo() {
                this.$router.push({name: 'IngredientInfo', params: {cartridgeInfo: this.cartridgeInfoServer}})
            },
            //跳转到我的记录
            myRecord() {
                this.$router.push({name: 'MyRecord'})
            },
            //预热点击
            preheatClick() {
                this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_PREHEAT
            },
            //温度点击
            temperatureClick() {
                this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_TEMPERATURE
            },
            //子组件发射出来的事件数据
            tasteSettingChanged(type) {
                const lastTasteType = this.tasteType
                this.tasteType      = type

                if (type === StoreType.TASTE_TYPE.TASTE_TYPE_PREHEAT) {  //如果是预热状态

                    this.$store.commit(StoreType.COMMIT_TYPE.COMMIT_SWITCH_TEMPERATURE_MODE, -1)
                    if (this.cartridgeInfo.specFunction !== DEVICE_TYPE_MODEL_S) { //但是又不是wax设备，则读取预热的信息
                        bluetoothRepository.writePreheat(PREHEAT_TYPE_ENTER, this.preheatInfo.preheatTemperature,
                            this.preheatInfo.preheatSecond)
                    }
                }
                // else if ((lastTasteType === StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE) || (lastTasteType === StoreType.TASTE_TYPE.TASTE_TYPE_TEMPERATURE) ) { //如果是在dosage use或者diy curve界面
                //     //如果上一次是预热的话，就跳转到其他页面,什么都不操作
                // }
                else if (type === StoreType.TASTE_TYPE.TASTE_TYPE_TEMPERATURE) {
                    if (this.cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_X ||
                        this.cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_E) {
                        bluetoothRepository.writeTemperatureMode(this.$store.state.temperatureMode)
                    }
                } else {
                    if (this.cartridgeInfo.specFunction !== DEVICE_TYPE_MODEL_S) {
                        this.$store.commit(StoreType.COMMIT_TYPE.COMMIT_SWITCH_TEMPERATURE_MODE, -1)
                        bluetoothRepository.writePreheat(PREHEAT_TYPE_STOP, 50, 35)
                    }
                }
            },
            //预热参数改变
            preheatParamsChanged(item) {
                switch (item) {
                    case 1:
                        bluetoothRepository.writePreheat(PREHEAT_TYPE_ENTER, 55, 30)
                        this.preheatSecond = 30
                        break;
                    case 2:
                        bluetoothRepository.writePreheat(PREHEAT_TYPE_ENTER, 50, 45)
                        this.preheatSecond = 45
                        break;
                    case 3:
                        bluetoothRepository.writePreheat(PREHEAT_TYPE_ENTER, 60, 15)
                        this.preheatSecond = 15
                        break;
                    case 4:
                        bluetoothRepository.writePreheat(PREHEAT_TYPE_ENTER, this.preheatInfo.preheatTemperature,
                            this.preheatInfo.preheatSecond)
                        this.preheatSecond = this.preheatInfo.preheatSecond
                        break;
                }
            },
            onPopupOpened() {
            }
        },

        watch: {
            isFire(val, oldVal) {
                if (val === oldVal) return;
                if (this.$refs.smokeProgress) {
                    this.$refs.smokeProgress.stop();
                    if (val) {
                        if (this.tasteType === StoreType.TASTE_TYPE.TASTE_TYPE_PREHEAT) {
                            this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE
                        }
                        this.progress = {current: 0, end: 2160};
                        this.$refs.smokeProgress.start();
                    } else {
                        this.$refs.smokeProgress.stop();
                    }
                }
            },
            isInsert(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {

                        this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE
                        this.progress  = {
                            current: 0,
                            end    : 360
                        };
                        this.$refs.smokeProgress.start();

                        bluetoothRepository.fetchDeviceData().then(data => {
                            return this.getCartridgeInfoFromServer()
                        })
                        // let newPodId = ''
                        // bluetoothRepository.readPodId().then(podId => {
                        //     newPodId = podId
                        // }).then(data => {
                        //     return bluetoothRepository.readCartridgeInfo().then((cartridgeInfo) => {
                        //         this.$store.commit(StoreType.COMMIT_TYPE.COMMIT_DEVICE_INFO, {
                        //             ...this.$store.state.deviceInfo,
                        //             newPodId,
                        //             cartridgeInfo
                        //         });
                        //         return bluetoothRepository.updatePreheatAndDiyTemperature(cartridgeInfo).then(() => {
                        //             return cartridgeInfo
                        //         });
                        //     });
                        // }).then(cartridgeInfo => {
                        //     return this.updateCartridgeInfoFromServer(newPodId, cartridgeInfo.batchNum).then(() => {
                        //         return cartridgeInfo
                        //     });
                        // })
                    } else {
                        this.$refs.smokeProgress.stop();
                    }
                } else {
                    this.$refs.smokeProgress.stop();
                }
            },

            switchTemperatureMode(newValue, oldValue) {
                if (newValue === RECEIVED_ENTER_DIY_TEMPERATURE_CURVE) {
                    this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_TEMPERATURE
                } else if (oldValue === RECEIVED_EXIT_DIY_TEMPERATURE_CURVE) {
                    this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE
                }
            },
        },

        computed: {
            switchTemperatureMode() {
                return this.$store.state.switchTemperatureMode
            },
            deviceInfo() {
                return this.$store.state.deviceInfo;
            },

            smokeData() {
                return this.$store.state.syncData;
            },

            isFire() {
                return this.$store.state.deviceStatus.isFire;
            },

            isInsert() {
                const isInsert = this.$store.state.deviceStatus.isInsert
                if (!isInsert) {
                    this.second = 0
                }
                return isInsert;
            },

            cartridgeInfoServer() {
                let cartridgeInfoServer = this.$store.state.cartridgeInfoServer;
                let secondInTake        = 0
                let mainProportion      = 0
                if (cartridgeInfoServer) {
                    const cartridgeInfo = this.$store.state.deviceInfo.cartridgeInfo
                    if (!cartridgeInfo) return null
                    if (cartridgeInfo.ingredirentType === INGREDIRENT_TYPE_SUPPLEMENT) {
                        secondInTake = cartridgeInfoServer.dose * 1000 / 0.8 / 450 * cartridgeInfoServer.dose
                    } else {
                        secondInTake = cartridgeInfoServer.dose * 1000 / 450 * cartridgeInfoServer.dose
                    }

                    if (cartridgeInfoServer.formulaList && cartridgeInfoServer.formulaList.length > 0) {
                        mainProportion = cartridgeInfoServer.formulaList[0].proportion
                    }
                    const maxProportion = secondInTake * mainProportion / 100 / 100 * 12
                    const intake        = {
                        secondInTake,
                        mainProportion,
                        maxProportion
                    }
                    cartridgeInfoServer = {...cartridgeInfoServer, ...intake}
                    return cartridgeInfoServer
                }
                return null
            },

            battery() {
                const battery   = this.$store.state.deviceInfo.battery
                let batteryIcon = this.batteryIcon.level4
                if (battery <= 40) {
                    batteryIcon = this.batteryIcon.level1
                } else if (battery <= 55) {
                    batteryIcon = this.batteryIcon.level2
                } else if (battery <= 70) {
                    batteryIcon = this.batteryIcon.level3
                } else if (battery === 0) {
                    batteryIcon = this.batteryIcon.level4
                }
                return batteryIcon
            },
            logo() {
                let icon = this.logoIcon.unicore
                if (this.$store.state.deviceInfo.cartridgeInfo) {
                    switch (this.$store.state.deviceInfo.cartridgeInfo.brandName) {
                        case 2:
                            icon = this.logoIcon.unicore
                            break;
                        case 3:
                            icon = this.logoIcon.dime
                            break;
                        case 4:
                            icon = this.logoIcon.angel
                            break;
                        case 5:
                            icon = this.logoIcon.biscuits
                            break;
                        default:
                            icon = null
                    }
                }

                return icon
            },

            preheatInfo() {
                return this.$store.state.preheatInfo
            },

            temperatureMode() {
                return this.$store.state.temperatureMode
            },

            preheatStatus() {
                const preheatStatus = this.$store.state.preheatStatus
                switch (preheatStatus) {
                    case PREHEAT_STATUS.START_PREHEAT:
                        this.tasteType    = StoreType.TASTE_TYPE.TASTE_TYPE_PREHEAT
                        this.isPreheating = true
                        break
                    case PREHEAT_STATUS.END_PREHEAT:
                        this.isPreheating = false
                        break
                    case PREHEAT_STATUS.ENTER_PREHEAT:
                        this.tasteType = StoreType.TASTE_TYPE.TASTE_TYPE_PREHEAT
                        break
                    case PREHEAT_STATUS.EXIT_PREHEAT:
                        this.tasteType    = StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE
                        this.isPreheating = false
                        break
                }
                return preheatStatus
            },
            isModelSDevice() {
                return this.deviceInfo.cartridgeInfo.specFunction === DEVICE_TYPE_MODEL_S
            }
        },

        activated() {
            this.syncData()
                .then(data => {
                    return bluetoothRepository.readSmokeData()
                })
                .catch(err => {
                    log("同步失败 = " + err)
                })

            this.getCartridgeInfoFromServer()
        },

        mounted() {
        },
    }
</script>

<style lang="scss">
    #home {
        background: #FCF5F5;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home-container {
        flex: 1;
        width: 100%;
        padding-bottom: 60px;

        .top-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .left {
                flex: 1;
                text-align: left;
            }

            .center {
                flex: 4;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .right {
                flex: 1;
                text-align: right;
            }
        }

        .taste-fun-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            color: #000000;
            font-size: 12px;
            width: 100%;

            .left {
                flex: 1;
            }

            .center {
                flex: 2;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                align-content: center;
            }

            .right {
                flex: 1;
            }
        }

        .bluetooth-battery-container {
            display: flex;
            margin-top: 16px;
            flex-direction: row-reverse;
            align-items: center;
            width: 100%;

            .battery {
                width: 8px;
                height: 18px;
                margin-right: 16px;
            }

            .disconnect-bluetooth {
                width: 13px;
                height: 15px;
                margin-right: 16px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                @include bg-image("../assets/Bluetooth");
            }
        }

        .logo {
            margin-top: 32px;
            width: 120px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            @include bg-image("../assets/dime-logo");
        }

        .more {
            position: absolute;
            padding: 2px;
            width: 13px;
            height: 13px;
            right: 16px;
            top: 38px;
            background-repeat: no-repeat;
            background-position: right;
            background-size: cover;
            @include bg-image("../assets/more");
        }


        .info-container {
            position: fixed;
            bottom: 0px;
            width: 100%;

            .btn-container {

                background: #FCF5F5;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;

                .left {
                    flex: 1;
                    text-align: center;
                }

                .center {
                    flex: 1;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .right {
                    flex: 1;
                    text-align: center;
                }


                .ingre-btn {
                    color: #000000;
                    border-radius: 12px;
                    padding: 12px;
                    text-align: center;
                    background: #FDC36B;
                    border: none;
                }
            }
        }

    }

    .logo-min-height {
        min-height: 90px;
    }

</style>