<template>
    <div class="scan">
        <p class="placeholder">Click Part to start connect</p>
        <div class="start-pairing-container">
            <button class="start-pairing" @click="startPair">START PAIRING</button>
        </div>
        <van-popup v-model="showBindDialog" class="bind-dialog" round opened="onPopupOpened">
            <BindDeviceDialog :is-bind="isBind" :mac-address="macAddress" v-show="showBindDialog"
                              v-on:onCloseBindDialog="onCloseBindDialog"/>
        </van-popup>
    </div>
</template>

<script>
    import bluetoothRepository from '../repository/bluetooth/BluetoothRepository'
    import {mapState} from 'vuex'
    import StoreType from "../vuex/StoreType"
    import BindDeviceDialog from "./BindDeviceDialog"

    export default {
        name      : "Scan",
        components: {BindDeviceDialog},
        data() {
            return {
                showBindDialog: false,
                isBind        : false,
                macAddress    : ''
            }
        },
        methods   : {
            //开始匹配蓝牙设备
            startPair() {
                bluetoothRepository.startPair((macAddress, isBind) => {
                    if (!isBind) { //没有绑定的设备，弹出对话框，去服务器查询
                        this.macAddress     = macAddress
                        this.isBind         = isBind
                        this.showBindDialog = true
                    }
                })
            },
            onCloseBindDialog() {
                this.showBindDialog = false

                bluetoothRepository.disconnect()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .scan {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .bind-dialog {
            width: 80%;
        }

        .start-pairing-container {
            flex: 1;
        }

        .placeholder {
            text-align: center;
            height: 200px;
        }

    }

    .start-pairing {
        background: #ED6C0F;
        color: #f6f6f6;
        border: 0;
        padding: 16px;
        border-radius: 32px;
    }
</style>