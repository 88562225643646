<template>
    <div class="formula-item ml-40 mr-40">
        <div class="proportion-info">
            <span class="left ">{{formula.component}}</span>
            <span class="center"></span>
            <span class="right">{{this.currentProportionStr}}</span>
        </div>
        <div class="proportion-progress mt-8">
            <div class="intake-progress" :style="`width:${percent}%`">

            </div>
        </div>
    </div>
</template>

<script>
    import {INGREDIRENT_TYPE_SUPPLEMENT} from "../repository/bluetooth/BluetoothRepository"
    import log from "../repository/log/log"

    export default {
        name    : "FormulaItem",
        props   : {
            formula      : {
                type   : Object,
                default: {}
            },
            isFire       : {
                type   : Boolean,
                default: false
            },
            second       : {
                type   : Number,
                default: 0
            },
            maxProportion: {
                type   : Number,
                default: 0
            },
            dose         : {
                type   : Number,
                default: 0
            }
        },
        data() {
            return {
                timer: null,
            }
        },
        watch   : {
            isFireStatus(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        timer = setInterval(() => {
                            //更新进度百分比
                            if (this.formula.status === 0) {


                            }
                        }, 16)
                    } else {
                        clearInterval(timer)
                    }
                }
            }
        },
        computed: {
            progress() {
                return `0.00mg`
            },
            isFireStatus() {
                return this.isFire
            },
            currentProportion() {
                let secondIntake = 0
                if (this.$store.state.deviceInfo.cartridgeInfo) {
                    if (this.$store.state.deviceInfo.cartridgeInfo.ingredirentType === INGREDIRENT_TYPE_SUPPLEMENT) {
                        secondIntake = this.dose * 1000 / 0.8 / 450 * this.dose * this.formula.proportion
                    } else {
                        secondIntake = this.dose * 1000 / 450 * this.dose * this.formula.proportion
                    }

                    //主要成分
                    if (this.formula.status === 0) {
                        return (secondIntake * this.second / 100 / 100).toFixed(2)
                    } else {
                        //次要成分 转成微克 * 1000
                        return (secondIntake * this.second / 10000 / 100 * 1000).toFixed(2)
                    }
                } else {
                    return 0
                }

            },
            currentProportionStr() {
                //主要成分
                if (this.formula.status === 0) {
                    return `${this.currentProportion}mg`
                } else {
                    //次要成分 转成微克 * 1000
                    return `${this.currentProportion}ug`
                }
            },
            percent() {
                let percent = parseFloat(this.currentProportion) / this.maxProportion
                if (this.formula.status === 0) {
                    percent = percent * 100
                    log(`percent = ${percent}`)
                } else {
                    percent = percent / 100 * 100
                }
                return parseInt(percent.toFixed())
            }
        }
    }
</script>

<style lang="scss" scoped>
    .formula-item {
        font-size: 13px;

        .proportion-info {
            display: flex;
            flex-direction: row;
            width: 100%;
            color: #000000;
        }

        .left {
            flex: 1;
            text-align: left;
        }

        .center {
            flex: 4;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .right {
            flex: 1;
            text-align: right;
        }

        .proportion-progress {
            background: #EDE0DC;
            height: 4px;
            overflow: hidden;
            border-radius: 8px;

            .intake-progress {
                height: 100%;
                background-image: linear-gradient(to right, #FED0AE, #FFAF2D);
            }
        }

    }
</style>