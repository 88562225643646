<template>
    <div class="taste-setting-container">
        <div class="taste-setting-item"
             :class="{selected: tasteType === TASTE_TYPE.TASTE_TYPE_PREHEAT}"
             @click="preheatClick">
            <div class="taste-setting-preheat-img"
                 :class="[tasteType === TASTE_TYPE.TASTE_TYPE_PREHEAT?'selected':'normal']"></div>
            <div class="mt-8">Preheat<br>Setting</div>
        </div>
        <div class="taste-setting-item "
             :class="{selected: tasteType === TASTE_TYPE.TASTE_TYPE_DOSAGE}"
             @click="dosageClick">
            <div class="taste-setting-dosage-img"
                 :class="[tasteType === TASTE_TYPE.TASTE_TYPE_DOSAGE?'selected':'normal']"></div>
            <div class="mt-8">Draw<br>Dosage</div>
        </div>
        <div class="taste-setting-item"
             :class="{selected : tasteType === TASTE_TYPE.TASTE_TYPE_TEMPERATURE}"
             @click="temperatureClick">
            <div class="taste-setting-temperature-curve-img"
                 :class="[tasteType === TASTE_TYPE.TASTE_TYPE_TEMPERATURE?'selected':'normal']"></div>
            <div class="mt-8">Temperature<br>Curve</div>
        </div>
    </div>
</template>

<script>
    import StoreType from "../vuex/StoreType"
    import BluetoothRepository from "../repository/bluetooth/BluetoothRepository"
    import store from "../vuex/AppStore"

    export default {
        name   : "TasteSettingContainer",
        props  : {
            tasteType: {
                type   : Number,
                default: StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE
            }
        },
        data() {
            return {
                TASTE_TYPE: StoreType.TASTE_TYPE
            }
        },
        methods: {
            preheatClick() {
                store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, BluetoothRepository.ENTER_PREHEAT)
                this.$emit("tasteSettingChanged", StoreType.TASTE_TYPE.TASTE_TYPE_PREHEAT)
            },
            dosageClick() {
                store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, BluetoothRepository.EXIT_PREHEAT)
                this.$emit("tasteSettingChanged", StoreType.TASTE_TYPE.TASTE_TYPE_DOSAGE)
            },
            temperatureClick() {
                store.commit(StoreType.COMMIT_TYPE.COMMIT_PREHEAT_STATUS, BluetoothRepository.EXIT_PREHEAT)
                this.$emit("tasteSettingChanged", StoreType.TASTE_TYPE.TASTE_TYPE_TEMPERATURE)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .taste-setting-container {
        color: #111111;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        text-align: center;

        .taste-setting-item {
            color: #111111;
            display: flex;
            padding: 10px 0;
            flex: 1;
            margin: 0 4px;
            flex-basis: 10px;
            flex-direction: column;
            align-content: center;
            align-items: center;
            background: #333333;
            border-radius: 16px;

            &.selected {
                color: #F6F6F6;
                background: #ED6C0F;
            }

            .taste-setting-preheat-img {
                width: 20px;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                &.normal {
                    @include bg-image("../assets/taste/preheat_setting_n");
                }

                &.selected {
                    @include bg-image("../assets/taste/preheat_setting_s");
                }
            }

            .taste-setting-dosage-img {
                width: 20px;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                &.normal {
                    @include bg-image("../assets/taste/draw_dosage_n");
                }

                &.selected {
                    @include bg-image("../assets/taste/draw_dosage_s");
                }

            }

            .taste-setting-temperature-curve-img {
                width: 20px;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                &.normal {
                    @include bg-image("../assets/taste/temperate_curve_n");
                }

                &.selected {
                    @include bg-image("../assets/taste/temperate_curve_s");
                }
            }

            .preheat-second {
                font-size: 10px;
            }
        }

        .checked {
            background: #ED6C0F;
        }
    }
</style>