<template>
    <div class="my-record-container">

        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
                <span class="back" @click="backClick"></span>
            </div>
            <span class="title-with-icon">MyRecord</span>
            <div class="right-menu"></div>
        </div>

        <div class="my-record">
            This feature will be support soon
        </div>

    </div>
</template>

<script>
    export default {
        name: "MyRecord",
        methods: {
            backClick() {
                this.$router.back()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-record-container {
        color: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        justify-content: center;
        .my-record {
            margin-top: 200px;
        }
    }

    .title {
        flex: 4;
        font: 18px bold;
        margin-right: 11px;
        text-align: center;

        ::after {
            width: 13px;
            height: 7px;
            margin-left: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            @include bg-image("../assets/ic_bottom_expand");
        }
    }

</style>