<template>
    <div class="improve-container">

        <div class="avatar-container">
            <!--<van-uploader :after-read="afterRead">-->
                <!--<van-image-->
                        <!--width="80px"-->
                        <!--height="80px"-->
                        <!--fit="cover"-->
                        <!--:src="imagePath"-->
                        <!--round/>-->
            <!--</van-uploader>-->
            <!--<span class="mt-8">Upload</span>-->
        </div>

        <div class="improve-info-container" v-for="(item,i) in formData">
            <span class="item ml-16">{{item.left}}</span>
            <template v-if="i === 1">
                <van-radio-group v-model="item.model" direction="horizontal" checked-color="#ED6C0F">
                    <van-radio name="1">Male</van-radio>
                    <van-radio name="0">Female</van-radio>
                </van-radio-group>
            </template>
            <template v-else>
                <div class="mr-16">
                    <van-field v-model="item.right" class="filed-background"
                               :placeholder="item.placeholder"
                               input-align="right"/>
                </div>
            </template>
        </div>


        <div class="confirm-container" @click="onConfirm">
            <div class="confirm-btn">Confirm</div>
        </div>

        <div class="toolbar pt-16 pb-16">
            <div class="back-container">
            </div>
            <span class="title"></span>
            <span class="right" @click="nextTime">Next time</span>
        </div>
    </div>
</template>

<script>
    import apiService from '../repository/api/ApiService'
    import storeRepository from '../repository/store/StoreRepository'
    import log from "../repository/log/log"

    export default {
        name    : "Improve",
        data() {
            return {
                imagePath: require('../assets/default-avatar@3x.png'),
                formData : [
                    {
                        left       : "Nickname",
                        right      : '',
                        placeholder: '3–50 character',
                    },
                    {
                        left : "Gender",
                        model: `1`
                    },
                    {
                        left       : "Age",
                        right      : '',
                        placeholder: 'age in 18 ~ 100',
                    },
                    {
                        left       : 'Zip',
                        right      : '',
                        placeholder: 'input zipcode',
                    }
                ]
            }
        },
        methods : {
            nextTime() {
                this.$router.replace("Pair")
            },
            afterRead(file) {
                const formData = new FormData()
                formData.append("file", file.file, file.file.name)
                apiService.uploadFile(formData)
                    .then((data) => {
                        log(`Image path = ${data}`)
                        this.imagePath = data
                    })
            },

            onConfirm() {
                const commitData = {
                    nickName: this.formData[0].right,
                    gender  : this.formData[1].model,
                    picture : this.imagePath,
                    age     : this.formData[2].right,
                    zipCode : this.formData[3].right
                }
                apiService.modifyProfile(commitData).then((data) => {
                    let userInfo = this.$store.state.userInfo
                    userInfo     = {...userInfo, ...commitData}
                    storeRepository.saveUser(userInfo)
                    this.$router.replace("Pair")
                })
            }
        },
        computed: {}
    }
</script>

<style lang="scss" scoped>
    .improve-container {
        color: #000000;
        display: flex;
        flex-direction: column;
        align-items: center;

        .avatar-container {
            margin-top: 56px;
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #000000;
        }

        .improve-info-container {
            display: flex;
            width: 100%;
            flex-direction: row;
            align-items: center;
            border-bottom: 0.5px solid #666666;
            box-sizing: border-box;
            padding: 16px 0;

            .item {
                flex: 1;
            }
        }

        .van-uploader__input {
            text-align: center;
        }
    }

    .confirm-container {
        color: #000;
        width: 80%;
        margin-top: 100px;

        .confirm-btn {
            padding: 12px 0;
            text-align: center;
            background: #000000;
            border-radius: 16px;
        }
    }

    .van-field__body {
        line-height: 0;
    }

    .van-cell {
        padding: 0;
        font-size: 14px;
    }
    .right {
        color: #ED6C0F;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        padding: 16px;
    }
</style>